
.calendario-cuidador ion-img{
  width: 50px;
  margin-left: 50px;
  margin-inline: 20px;
}


.calendario-cuidador ion-item{
  margin-top: 20px;
}

.calendario-cuidador ion-list-header{ 
  background: rgba(240, 120, 192, 0.3);
}

.calendario-cuidador ion-list-header ion-img{
  width: 35px; 
}

.calendario-cuidador .header-nombre{
  font-size: .9em;
  line-height: 18px;
  color: #565656;
  text-transform: capitalize;
}

.calendario-cuidador .header-parentesco{
  font-size:.9em;
  line-height: 18px;
  color: #565656;
  text-transform: capitalize;
}


.calendario-cuidador .calendario-panel{   
   padding: 30px;
   width: 100%;
}

.calendario-cuidador .calendario-titulo{
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 500;
  font-size:1em;
  line-height: 21px;
  text-align: center;
  color: #565656;
}

.calendario-cuidador .calendario-panel .pastilla-calendario{
  text-align: center;
  position: relative;
  text-align: center;
}

.calendario-cuidador .calendario-panel .pastilla-calendario ion-button{
  height: 100% !important;
  --padding-start: none;
  --padding-end: none;
  color: black;
}

.calendario-cuidador .calendario-panel .pastilla-calendario ion-img{
  width: 100%;
  margin: 0px;
}

.calendario-cuidador .calendario-panel .pastilla-calendario span{
  display: block;
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -20%);
  font-size: 11px;
}


.calendario-cuidador .calendario-titulo ion-img{
  width: 50px;
}

.calendario-cuidador .calendario-datos{
  margin-top: 40px;
}

.calendario-cuidador .calendario-datos table{
  width: 100%;  
}

.calendario-cuidador .calendario-datos thead{
  background: rgba(240, 120, 192, 0.4);
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
}

.calendario-cuidador .calendario-datos tbody{
  font-size: 12px;
  line-height: 14px;
}

.calendario-cuidador .calendario-datos table td {
  border-left: 1px solid black; 
  border-right: 1px solid black; 
}



.calendario-cuidador .calendario-datos table tr td:first-child {
  border-left: 0;
}

.calendario-cuidador .calendario-datos table tr td:last-child {
  border-right: 0;
}

.calendario-cuidador .calendario-datos tr:nth-child(even){
  background: rgba(240, 120, 192, 0.15);;
}

.ion-modal-calendario{
  --width: 90%;
  --height: 450px;
  --border-radius: 8px;
}

.ion-modal-calendario ion-content{
  --padding-start: 20px;
  --padding-end: 20px;
  --padding-top: 20px;
}

.ion-modal-calendario .header{
  color: var(--color-allivia-rosa);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}

.ion-modal-calendario .contenido{
  font-size: .8em;
}

.ion-modal-calendario .contenido ion-col{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ion-modal-calendario .contenido ion-col ion-select{
  width: 100%;
}

.ion-modal-calendario .contenido ion-radio{
  color: black;
}

.ion-modal-calendario .contenido ion-img{
  width: 30px;
  padding: 0 5px;
}

.ion-modal-calendario .contenido ion-item ion-input{
  width: 40%;
}

.ion-modal-calendario .header ion-img{
  width: 40px;
}

.ion-modal-calendario .header ion-segment{
  font-size: .8em;
}

.ion-modal-calendario .panel-documentos .upload-panel{
  border-radius: 10px;
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: #E8E8E8;
  margin-top: 20px;
  margin-bottom: 10px;
}

.rbc-event{
  font-size: .6rem;
} */

.rbc-calendar {
  font-family: 'Ubuntu';  
}

.rbc-calendar .rbc-month-view {
  font-size: .7rem;
  
}

.rbc-button-link .rbc-show-more{
  color:aqua;
}

/*modificaciones calendario*/

.rbc-calendar{
  margin:12% 10px;
}

.rbc-btn-group{
  margin:6% 0;
}

.rbc-toolbar-label{
  text-transform: uppercase;
  font-size:1.5em;
  color:var(--aqua-main);
  text-align: right;
}

.rbc-toolbar button{
  --border:none;
  --font-size:.7em;
  --background:var(--aqua-main);
}


.rbc-toolbar{
  margin:3% 0;
}

.rbc-month-view{
  margin:5% 0;
}

.rbc-header{
  --padding:60px;
  background: var(--aqua-main);
  --font-size:.9em;
  text-align: center;
  color:#fff;
  font-weight: bold;
  font-variant: small-caps;
}

.rbc-agenda-table tr{
  --background-color: #565656;
}
.rbc-agenda-table{

  --text-transform: capitalize;
}
.rbc-toolbar .rbc-toolbar-label {
  font-size: 1.5em;
}
/*IOS*/

.ios .rbc-toolbar .rbc-toolbar-label{
  font-size:1.7em;
}

.lista_dato {
  --padding-start: 5px;
  --max-height: 20px;

}


/*IOS*/

.ios .lista_dato {
  --padding-start: 5px;
  --max-height: 20px;

}