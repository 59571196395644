.reset-password .mensaje-error{
  color: red;  
}

.lista-campos ion-item::part(native){ 
  --padding-start:2px;
  --padding-left: 2px;
  --padding-right:0px;
  --inner-padding-end: 0px;
}


