.ion-modal-confirmacion-eliminar{
  --width: 90%;
  --height: 180px;
  --border-radius: 8px;
}

.ion-modal-confirmacion-eliminar .header{
  color: var(--color-allivia-rosa);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}

.ion-modal-confirmacion-eliminar ion-content{
  --padding-start: 20px;
  --padding-end: 20px;
  --padding-top: 20px;
}

.ion-modal-confirmacion-eliminar .contenido .panel-confirmacion ion-item{
  --min-height: none;
  --padding-top:0px
}

.ion-modal-confirmacion-eliminar .header ion-img{
  width: 46px;
}

.ion-modal-confirmacion-eliminar .header ion-segment{
  font-size: 14px;
}


.ion-modal-confirmacion-eliminar{
  --width: 90%;
  --height: 200px;
  --border-radius: 8px;
}

.ion-modal-confirmacion-eliminar-pass .header{
  color: var(--color-allivia-rosa);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}

.ion-modal-confirmacion-eliminar-pass ion-content{
  --padding-start: 20px;
  --padding-end: 20px;
  --padding-top: 20px;
}

.ion-modal-confirmacion-eliminar-pass .contenido .panel-confirmacion ion-item{
  --min-height: none;
  --padding-top:0px
}

.ion-modal-confirmacion-eliminar-pass .header ion-img{
  width: 46px;
}

.ion-modal-confirmacion-eliminar-pass .header ion-segment{
  font-size: 14px;
}