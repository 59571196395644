#title_404{
    width:100%;
}
#title_404 p{
    color: var(--textos);
}
#upss{
    font-size:3em;
    color: var(--pink-second);
    font-weight: bold;
    text-align: center;
}

.error{
    color: var(--text-secondary);
    font-size:2em;
    font-weight: bold;
}
.aqua404 {
    color: var(--aqua-main);
    font-size: 5em;
    font-weight: bold;
}

#image{
    width:100%;
}