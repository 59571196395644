.openpay-example{
  width: 100%;
  height: 80%;  
}
.pago-panel{
  padding:5% 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.pago-panel ion-img{
  width: 60px;
}

.panel-boton-pago{
  padding-bottom: 50px;
}

.pagar{
  color: #65C8D0;
    font-variant: small-caps;
    text-align: center;
    font-weight: bold;
}
#contenedor_tarjetas{
  width:100%;
  display:block;
}

.panel-tarjetas-tipo{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.card{
  width:30%;
  padding:2%;
}

#payment-form{
  width:100%;
}
.pymnt-itm{
  width:100%;
}

.sctn-row{
  width:100%;
  text-align: left;
}
.sctn-col{
  width:100%;
  display: block;
}

.sctn-col div{
  padding:2%;
}

.sctn-col input{
  width:95%;
  background-color: #E8E8E8;
  border:none;
  padding:2%;
}
.sctn-col input:focus{
  border-color:#65C8D0;
}

.openpay{
  width:100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.logo{
  width:70%;
  font-size: 12;
  color:var(--text-secondary);
}

.img_log {
  width: 30%;
}

.img_log ion-img{
  padding: 18% 0;
}
.shield{
  width:100%;
  font-variant: small-caps;
  color:#2C2B2B;
  font-weight: bold;
  text-align: left;
}

.txt_titlospago{
  margin:0 5%;
}
.fechas_card{
  float:left;
  margin:2% 0;
  width:50%;
}
.datos_card {
  width:100%;
  padding:4% 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  
}
.codigo{
  padding-top: 18px;
}
.membresia_pago{
  text-align:left;

}
max-h1
.col_fechas{
  width: 100%;
  display: block;
  padding: 2%;

}
.cvv {
  width: 20px;
  height: 20px;
  background: #FFF503;
  border-radius: 10px;
  margin:2% 0 0 20%;
  text-align: center;
  font-weight: bold;
}
.mesyano{
  width:25%;
  float: left;
  display:contents;
}
#diag{
  min-height: 30px;
  background-color: #E8E8E8;
  float:left;
  display: contents;
  width:2%;
}
.mesyano input{
  width:50%;
  background-color: #E8E8E8;
  border: none;
  min-height: 30px;
}
.mesyano h2{
  background: yellow;
  font-weight: bold;
  font-size:1em;
  border-radius: 50%;
  width:25%;
  height: 30px;
  text-align: center;
  margin-top: 0px;
}

.tipo_mem {
  margin: 2% 0;
  width: 100%;
}

.membresia{
  margin-top: 20px;
  display: flex;  
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}


.tipo-pago{
  float:left;
  margin:-8% 68px 0 0;
}

.mem-rad{
  padding-right: 10px;
  float: left;
}
.mensual-anual{
  float:left;

}
input[type="radio"] {
  display: none;
}
input[type="radio"]+label:before {
  content: "";
  width: 26px;
  height: 26px;
  float: left;
  margin: 0.5em 0.5em 0 0;
  border: 2px solid #ccc;
  background: #fff;
  border-radius: 100%;
}
input[type="checkbox"]:checked+label:before {
  border-color: #0fbf12;
}
input[type="radio"]:checked+label:after {
  content: "";
  width: 0;
  height: 0;
  border: 8px solid #0fbf12;
  float: left;
  margin-left: -2.15em;
  margin-top: 1em;
  border-radius: 100%;
}

.ion-modal-cvv{
  --width: 90%;
  --height: 280px;
  --border-radius: 10px;
}

.ion-modal-cvv ion-content{
  --padding-start: 25px;
  --padding-end: 25px;
  --padding-top: 10px;
}

.rosa{
  color: var(--pink-second);
  font-weight: bold;;
}

.datos-suscripcion{
  font-size: 12px;
}

.datos-suscripcion ion-title{
  font-size: 12px;
  text-align: left;
  padding: 0px;
}

.errormessage{
  padding: 2%;
  color: #eb445a;
}


/*vigencia membresia*/

.vigencia_fondo{
  z-index:999;
  position: absolute;
}