@font-face {
  font-family: 'alliviaFont';
  src: url('../fonts/allivia.otf');  
}

@font-face {
  font-family: 'prueba';
  src: url('../fonts/SyneMono-Regular.ttf');
  
}

@font-face {
  font-family: 'Ubuntu';
  src: url('../fonts/Ubuntu-Regular.ttf');  
}

@font-face {
  font-family: 'caregiversFont';
  src: url('../fonts/caregivers.ttf');  
}


:root{
  --color-allivia: #65c8d0;
  --color-allivia-rosa: #F078C0;  
  --color-allivia-dorado: #D29700;
  --color-allivia-gris: #c4c4c4;
 
  --ion-color-emergencia: #FF0000;
  --ion-color-emergencia-rgb: 255, 0, 0;
  --ion-color-emergencia-contrast: #ffffff;
  --ion-color-emergencia-contrast-rgb: 255, 255, 255;
  --ion-color-emergencia-shade: #e00000;
  --ion-color-emergencia-tint: #ff1a1a;    

  --ion-color-naranja: #FF6B00;
  --ion-color-naranja-rgb: 255, 0, 0;
  --ion-color-naranja-contrast: #ffffff;
  --ion-color-naranja-contrast-rgb: 255, 255, 255;
  --ion-color-naranja-shade: #e05e00;
  --ion-color-naranja-tint: #ff7a1a;  

  --ion-color-rosa: #F078C0;
  --ion-color-rosa-rgb: 240,120,192;
  --ion-color-rosa-contrast: #FFFFFF;
  --ion-color-rosa-contrast-rgb: 255,255,255;
  --ion-color-rosa-shade: #d36aa9;
  --ion-color-rosa-tint: #f286c6;

  --ion-font-family: 'Ubuntu';
  font-style: normal;
  font-weight: normal;  
}

.ion-padding {
  --padding-start: var(--ion-padding, 40px);
  --padding-end: var(--ion-padding, 40px);
  --padding-top: var(--ion-padding, 16px);
  padding-right: var(--ion-padding, 40px);
  padding-top: var(--ion-padding, 16px);
  padding-bottom: var(--ion-padding, 16px);
}

/*Para quitar los paddings de las listas*/
.ion-list ion-item{
  --padding-start: 5px ;
  --inner-padding-end: 5px
}

ion-title{
  color: #8a8a8a;
  font-size:1.2em;

}

.home-title{
  color: var(--color-allivia);
  font-family: alliviaFont;
  font-style: normal;
  font-weight: normal;
  font-size: 35px;
}

.ion-color-emergencia {
  --ion-color-base: var(--ion-color-emergencia);
  --ion-color-base-rgb: var(--ion-color-emergencia-rgb);
  --ion-color-contrast: var(--ion-color-emergencia-contrast);
  --ion-color-contrast-rgb: var(--ion-color-emergencia-contrast-rgb);
  --ion-color-shade: var(--ion-color-emergencia-shade);
  --ion-color-tint: var(--ion-color-emergencia-tint);
}

.ion-color-rosa {
  --ion-color-base: var(--ion-color-rosa);
  --ion-color-base-rgb: var(--ion-color-rosa-rgb);
  --ion-color-contrast: var(--ion-color-rosa-contrast);
  --ion-color-contrast-rgb: var(--ion-color-rosa-contrast-rgb);
  --ion-color-shade: var(--ion-color-rosa-shade);
  --ion-color-tint: var(--ion-color-rosa-tint);
}

.ion-color-naranja {
  --ion-color-base: var(--ion-color-naranja);
  --ion-color-base-rgb: var(--ion-color-naranja-rgb);
  --ion-color-contrast: var(--ion-color-naranja-contrast);
  --ion-color-contrast-rgb: var(--ion-color-naranja-contrast-rgb);
  --ion-color-shade: var(--ion-color-naranja-shade);
  --ion-color-tint: var(--ion-color-naranja-tint);
}

/*Background de los inputs*/
.native-input.sc-ion-input-md{
  height: 65px;
  border-radius: 3px;
  margin-top:2%;
  background:rgba(196, 196, 196, 0.3);
}

.native-input.sc-ion-input-ios{
  background: rgba(196, 196, 196, 0.3);
  --padding-start: 5px;
}

.btn-emergencia button{
  background: var(--color-emergencia);
}

ion-toast{  
  font-size: 16px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
}

ion-datetime{
  background: #C4C4C44D;
}

ion-select{
  background: #C4C4C44D;
}

.curp{
  text-transform: uppercase;
}

.allivia-emergencia-call ion-fab-button div{
  width: 100%;
  height: 100%;
  font-weight: bold;
  display: flex ;
  flex-direction: column;
  align-items: center !important;
  justify-content: center;
}

.allivia-emergencia-call ion-fab-button{
  font-size: 16px;
}

.allivia-emergencia-call ion-fab-button ion-icon{
  font-size: 25px;
}

.accordion-collapsed{
  padding-bottom: 4%;
}
.sc-ion-input-md-h{
  --padding-start: 5px;
}
.md ion-button{
  text-transform: none;
}

.native-input.sc-ion-input-md:first-letter {
  text-transform: capitalize;
}
.home-title {
  font-size: 2.25em;
}

/*IOS*/
.ios .home-title{
  font-size:2.25em;
}
.ios ion-title {
  font-size: 1em;
  font-weight: lighter;
  text-align: center;
}

.allivia-version{
  width: 100%;
  padding-top: 10px;
  text-align: center;  
  font-size: .8rem;
}