.cuidador h2{
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
 
}

.cuidador .titulo{
  text-transform: uppercase;
  color: var(--color-allivia-rosa);
}

.cuidador p{
  font-size: 13px;
  text-align: justify;
}

.cuidador ol{

  margin-left: 30px;
  padding: 0;
  font-size: 13px;
}

.cuidador li{
  margin: 15px 0;
  text-align: justify;
}

.lista_derechos li{
  --list-style-type: decimal;
}