.hidratacion{
  padding-left: 0px;
  padding-right: 0px;
}

.hidratacion .header{
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.hidratacion .header .imagen{
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  
}

.hidratacion .header .imagen span{
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 11px;
}

.hidratacion .header .imagen ion-img{
  width: 61px;
}

.hidratacion .header .titulo h1{
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  text-transform: uppercase;
  padding-left: 20px;
  padding-right: 20px;
  color: var(--color-allivia-rosa);
}

.hidratacion .contenido .titulo{
  margin-bottom: 17px;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: var(--color-allivia);
}

.hidratacion .contenido .mensaje{
  margin-bottom: 30px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: justify;
  color: #000000;
}

.hidratacion .contenido .mensaje span{
  color: var(--color-allivia);
}

.hidratacion .formula .titulo{
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  text-align: left;
  text-transform: uppercase;
  color: var(--color-allivia-rosa);
}

.hidratacion .formula .operacion{
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}
.hidratacion .formula .operacion div{
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  align-content: flex-end;
  justify-content: flex-end;
  flex: 1 .5;
}

.hidratacion .formula .operacion .opPeso ion-input input{
  width: 100%;
}

.hidratacion .formula .operacion .opPeso{
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  white-space: nowrap;
}

.hidratacion .formula .operacion .opConst{
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 41px;
  color: #000000;
  white-space: nowrap;
}

.hidratacion .formula .operacion .opResult{
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 41px;
  color: var(--color-allivia);
  display: flex;
  flex-direction: row;
  align-items: flex-end;  
}

.hidratacion .formula .operacion .opResult ion-img{
  width: 25px;
}

.hidratacion .formula .boton-calcula{
  text-align: right;
  padding-top: 15px;
}

.hidratacion .formula .boton-calcula ion-button{  
  width: calc(100% /3); 
  margin-right: 0px; 
}

.hidratacion .boton-agregar{
  text-align: center;
}

.hidratacion .boton-agregar ion-button{
  width: 60px;
  height: 60px;
}

.hidratacion .boton-agregar ion-button::part(native){
  padding: 0;  
}

.hidratacion .pregunta{
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #565656;
  display: flex;
  flex-direction: column;
}

.hidratacion .pregunta ion-label{
   padding-right: 10px; 
}

.hidratacion .pregunta div{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.hidratacion .pregunta div div{
  flex: 1 1;
}

.fondo-consumo-botella{
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;  
}

.consumo-botella{   
  height: 65vh;
  background: linear-gradient(360deg,rgba(255,255,255,0) 0%, rgba(101, 200, 208,1) var(--consumo-nivel), rgba(255,255,255,1)0%);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 72% 100%;
  width: fit-content;  
  flex: 1 1;
}

.hidratacion .contenido .consumo{
  margin-top: 15px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.hidratacion .contenido .consumo div{
  flex: 1 1;
}

.hidratacion .fechahidratacion{
  margin-top: 15px;
  font-style: normal;
  font-weight: 700;
  font-size: 1em;
  line-height: 18px;
  text-align: center;
  color: var(--color-allivia);
}

#sabias_que{
  margin: 5% 0;

}

#trigger-button{
  --color:#FF6B00;
  font-weight: bold;
  font-size:1.2em;
  text-align:left;
  background: none!important;
  width: 130px;
}

.ion-modal-sabiasque{
  --width: 90%;
  --height: 400px;
  --border-radius: 10px;
}

.ion-modal-sabiasque ion-content{
  --padding-start: 25px;
  --padding-end: 25px;
  --padding-top: 10px;
}

.control-consumo-agua ion-fab-button{
  --box-shadow: none;
  --background: #FFFFFF;
  --color: var(--color-allivia-rosa)
}

.control-consumo-agua ion-fab-button ion-icon{
  font-size: 50px;
  font-weight: bold;
}

