.cuestionario-uno .header{
  padding: 30px 60px 30px 60px;
  width: 100%;  
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: center;
  background: rgba(240, 120, 192, 0.1);
}

.cuestionario-uno .header .numero{
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;  
}

.cuestionario-uno .contenido{
  padding: 10px 60px 10px 60px;
}

.cuestionario-uno .contenido .subtitulo{
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 17px;
  text-align: justify;
}

.cuestionario-uno .contenido .instrucciones{
  padding-top: 15px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  text-align: justify;
}

.cuestionario-uno .contenido .instrucciones span{
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 17px;
  text-align: justify;
}

.cuestionario-uno .header .numero span{
  width: 30px;
  height: 30px;
  border-radius: 15px;
  color: #FFFFFF;
  background: var(--color-allivia);
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 30px;
}

.cuestionario-uno .header .titulo{
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
}
.cuestionario-uno .header .titulo span{
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  text-transform: uppercase;
  
  color: var(--color-allivia-rosa);
}

.cuestionario-uno .contenido .cuestionario{
  padding-top: 20px;
}

.cuestionario-uno .contenido .cuestionario .pregunta{
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  text-align: justify;
  color: #565656;
}

.cuestionario-uno .contenido .cuestionario .pregunta .categoria{
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 17px;
  text-align: justify;
  color: #565656;
}

.cuestionario-uno .contenido .cuestionario .respuestas{
  padding-top: 10px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 11px;
}

.cuestionario-uno .contenido .cuestionario .respuestas .resp-radio{  
  padding-top: 5px;
  width: 100%;
  background: rgba(240, 120, 192, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cuestionario-uno .contenido .cuestionario .respuestas .resp-radio .descripcion{
  padding-top: 5px;
  padding-bottom: 5px;
  background: rgba(244, 244, 244);
  text-align: center;
  height: 30px;
  width: 100%;
}

.cuestionario-uno .contenido .resultado .panel-mensaje{
  height: 130px;
  left: 77px;
  top: 1170px;
  background: #DEDEDE80;
  border-radius: 40px 5px 5px 40px;
}

.cuestionario-uno .contenido .resultado .panel-mensaje {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.cuestionario-uno .contenido .resultado .panel-mensaje .nota{
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  padding-left: 15px;
  padding-right: 15px;
  text-align: justify;
  hyphens: auto;
}



.cuestionario-uno .contenido .resultado .panel-mensaje .puntaje{
  height:125px;
  width: 125px;
  border-radius: 50px;
  background: var(--color-allivia);
  color: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: center;
}



.cuestionario-uno .contenido .resultado .panel-mensaje .numero{
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 31px;
  text-align: center;
}

.cuestionario-uno .contenido .resultado .panel-mensaje .texto{
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 13px;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 20px;
}

.cuestionario-uno .contenido .boton-siguiente{
  text-align: right;
  padding: 10px;
  padding-right: 0px;
}

.cuestionario-uno .contenido .boton-siguiente ion-button{
  --color: var(--color-allivia-rosa);
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;  
  /* width: calc(100%/3); */
  padding-right: 0px;
}

.bts{
  background-color: hotpink;
  border-radius: 3px;
  color:#FFFFFF;
  font-weight: bold;
  --width: 85%;
}