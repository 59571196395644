.ion-modal-diagnosticos{
  --width: 90%;
  --height: 170px;
  --border-radius: 8px;
}

.ion-modal-diagnosticos ion-content{
  --padding-start: 10px;
  --padding-end: 10px;
  --padding-top: 10px;
}

.ion-modal-diagnosticos ion-label{
  font-size: 11px;
  line-height: 13px;
}



.panel-paciente .avatar{ 
  text-align: center;
  margin: 0 auto;
  margin-bottom: 10px;
}
.panel-paciente .btn-avatar{  
  transform: translate(30px,-50px); /* Equivalente a translateX(10px) */
  --padding-start: 5px;
  --padding-end: 5px;
  --background: var(--color-allivia-gris);
}

.panel-paciente .nombreCompleto{
  text-transform: capitalize;
}

.panel-paciente ion-col{
  padding: 0px;
}



.panel-paciente-datos ion-label{
  font-size: .8rem;
}

.panel-paciente-form ion-accordion{
  background: rgba(222, 222, 222, 0.5);
}

.panel-paciente-form ion-accordion ion-item{
  --background: var(--color-allivia);
  --color: white;
  font-weight: bold;
  font-size: 16px;
}

.panel-paciente-form ion-accordion .link-curp{
  color: var(--color-allivia-rosa);
  font-weight: 900;
  font-size:.9em;
  text-transform: none;
  text-align: left;
}

.panel-paciente-form ion-accordion ion-icon{
  color: white;
}

.panel-paciente-form ion-accordion .panel-diagnosticos .panel-acciones{  
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content:center;
}
.panel-paciente-form ion-accordion .panel-diagnosticos .panel-acciones ion-button::part(native){      
  padding: 0px;
}

.panel-paciente-form ion-accordion .panel-diagnosticos .panel-add-diag{
  text-align: right;
}

.panel-paciente-form ion-accordion .panel-diagnosticos .panel-add-diag ion-button::part(native){      
  padding: 5px;
}

.panel-paciente-form ion-accordion .panel-benef .panel-acciones{  
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content:center;
}

.panel-paciente-form ion-accordion .panel-benef .panel-add-benef{
  text-align: right;
}

.panel-paciente-form ion-accordion .panel-benef .panel-acciones ion-button::part(native){      
  padding: 0px;
}

.panel-paciente-form ion-accordion .upload-panel{
  border-radius: 10px;
  width: 100%;
  min-height: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #E8E8E8;
  margin-top: 20px;
  margin-bottom: 10px;
  color: #747474;
  padding: 10px;
  text-align: center;
}

.panel-paciente-form ion-accordion .upload-panel .upload-panel-buttons{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.panel-paciente-form ion-accordion .ds-titulo{
  color: var(--color-allivia);
  font-size: 12px;
  font-weight: bold;
}

.panel-paciente-form ion-accordion .ds-aviso{
  font-size: 12px;
}

.naranjanew {
  color: var(--naranja-terc);
  font-weight: bold;
  font-variant: small-caps;
  font-size:1em;
}