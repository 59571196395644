#terminos{
    width:100%;
}
#terminos h1{
    font-weight: bold;
    text-align: center;
    color:#F078C0;
}
p{
    text-align: justify;
}
ul li{
    text-align: justify;
}
ol li{
    text-align: justify;
    
}

.sin_lista li {
    list-style: none;
}
.aviso li::before {

    /* Unicode for a bullet */
    content: "\2022";

    /* Styles for Indentation*/
    color: #65C8D0;

    display: inline-block;
    width: 1em;
    margin-left: -1em;
}

li {
    color: black;
}
.bold{
    font-weight: bold;
}

/*aviso*/

#aviso {
    width: 100%;
}

#aviso h1 {
    font-weight: bold;
    text-align: center;
    color: #F078C0;
}

ion-title{
    text-align:center;
}