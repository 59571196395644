.promociones .header{
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  text-transform: uppercase;
  color: var(--color-allivia-rosa);
}

.promociones .sin-promociones{
  padding-top: 100px ;
}

.promociones .sin-promociones .mensaje1{
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  color: #000000;
}

.promociones .sin-promociones .mensaje2{
  padding-top: 20px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: var(--color-allivia-gris);
}

.promociones .con-promociones{
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  text-transform: uppercase;
  color: var(--color-allivia-rosa);
}