.minv-premium .header{
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  text-transform: uppercase;
  color: var(--color-allivia);
}

.minv-premium .mensaje {
  padding-top: 50px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #000000
}


.minv-premium .mensaje .titulo{
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  color: #565656;
  padding-bottom: 20px;
}

.minv-premium .sin-invitacion{  
  padding-top: 20px;
  font-size: 16;
  text-align: center;
}

.minv-premium .invitado{  
  padding-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.minv-premium .invitado .im-inv{  
  background: var(--color-allivia-rosa);
  width: 54px;
  height: 54px;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  text-transform: uppercase;  
  color: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.minv-premium .invitado .datos-inv{
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  text-transform: uppercase;
  width: calc(100% - 60px);
}

.minv-premium .invitado .datos-inv .datos-estatus{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  text-transform: uppercase;
}

.minv-premium .invitado .datos-inv .datos-estatus .estatus{
  color: var(--color-allivia);
  width:100%;
  margin: 0px;
  padding: 0px;
}

.minv-premium .invitado .datos-inv .datos-estatus .estatus .status-premium{
  color: var(--color-allivia-dorado);
  width:100%;
  margin: 0px;
  padding: 0px;
}


.minv-premium .invitado .accion{
  --color: var(--color-allivia-rosa);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  align-content: flex-end;
  height: 100%;
}

.minv-premium .invitado .accion ion-button{
  --color: var(--color-allivia-rosa);
  width: 20px;
  height: 20px;
  flex: fit-content;
  padding-top: 25px;
}

.minv-premium .invitado .accion ion-button::part(native){
  padding: 0px
}

.modal-aceptar-invitado{
  --width: 90%;
  --height: 270px;
}

.modal-aceptar-invitado .contenido{
  padding: 10% 10%;  
}

.modal-aceptar-invitado .imagen{
  width: 100%;
}
.modal-aceptar-invitado .imagen ion-img{
  width: 55px;
  align-items: center;
  margin: auto;
}

.modal-aceptar-invitado .mensaje1{
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  color: #565656;
  text-align: center;
  padding-top: 20px;
}

.modal-aceptar-invitado .mensaje2{
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #565656;
  text-align: justify;
  padding-top: 20px;
}

.modal-aceptar-invitado .mensaje3{
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #565656;
  text-align: justify;
  padding-top: 20px;
}

.modal-aceptar-invitado .botones{
  padding-top: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;  
}

.modal-aceptar-invitado .botones .dl{
  width: 100%;
  border-right: 1px solid var(--color-allivia-rosa);
  border-top: 2px solid var(--color-allivia-rosa) ;
}

.modal-aceptar-invitado .botones .dr{
  width: 100%;
  border-left: 1px solid var(--color-allivia-rosa);
  border-top: 2px solid var(--color-allivia-rosa) ;
}

.modal-aceptar-invitado .botones ion-button{
  width: 100%;
  --color: var(--color-allivia-rosa)
}


.modal-estatus-invitado{
  --width: 90%;
  --height: 180px;
}

.modal-estatus-invitado .contenido{
  padding: 10% 10%;  
}

.modal-estatus-invitado .mensaje1{
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  color: #565656;
  text-align: center;
  padding-top: 20px;
}

.modal-estatus-invitado .acciones{
  padding-top: 20px;
}

.publicidad-img-premium{
  background: #3F3F3F;
  padding: 10px;
  margin-top: 50px;
}