.acerca{

}

.acerca h1{
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  text-transform: lowercase;
  color: var(--color-allivia);
}

.acerca p{ 
  font-size: 14px;
  line-height: 16px;
  text-align: justify;
  padding:7% 0;
}

.acerca .caregiver{
  font-weight: 500;
}

.acerca h3{ 
  font-size: 14px;
  line-height: 16px;
  font-weight: 700;
}