#preguntas_fre{
    width:100%;
}
.pregunta{
    width:100%;
}
.pregunta h1{
    color:#F078C0;
    font-weight: bold;
    font-size:1.3em;
}

.pregunta p{
    font-size:1em;
    text-align: justify;

}
.allivia{
    font-size: 1em;
    font-weight: bold;
}