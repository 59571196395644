.tratamientos ion-img{
  width: 60px;
  margin-left: 50px;
  margin-inline: 20px;
}

.pleca-tratamientos{
  padding-top: 0px;
}

.tratamientos ion-item{
  margin-top: 20px;
}

.tratamientos ion-list-header{ 
  background: rgba(240, 120, 192, 0.3);
}

.tratamientos ion-list-header ion-img{
  width: 35px; 
}

.tratamientos .header-nombre{
  font-size: 16px;
  line-height: 18px;
  color: #565656;
  text-transform: capitalize;
}

.tratamientos .header-parentesco{
  font-size: 16px;
  line-height: 18px;
  color: #565656;
  text-transform: capitalize;
}


.tratamientos .tratamiento-panel{   
   padding: 30px;
   width: 100%;
}

.tratamientos .tratamiento-titulo{
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 500;
  line-height: 21px;
  color: #565656;
}

.tratamientos .tratamiento-panel .pastilla-tratamiento{
  position: relative;
  text-align: center;
}

.tratamientos .tratamiento-panel .pastilla-tratamiento ion-button{
  height: 100% !important;
  --padding-start: none;
  --padding-end: none;
  color: black;
}

.tratamientos .tratamiento-panel .pastilla-tratamiento ion-img{
  width: 100%;
  margin: 0px;
}

.tratamientos .tratamiento-panel .pastilla-tratamiento #ver_tratamiento p{
  display: block;
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -20%);
  width: 75%;
  white-space:nowrap;
  overflow: hidden;
}


.tratamientos .tratamiento-titulo ion-img{
  width: 50px;
}

.tratamientos .tratamiento-datos{
  margin-top: 40px;
}

.tratamientos .tratamiento-datos table{
  width: 100%;  
}

.tratamientos .tratamiento-datos thead{
  background: rgba(240, 120, 192, 0.4);
  font-weight: 500;
  line-height: 14px;
}

.tratamientos .tratamiento-datos tbody{
  font-size: 12px;
  line-height: 14px;
}

.tratamientos .tratamiento-datos table td {
  border-left: 1px solid black; 
  border-right: 1px solid black; 
}



.tratamientos .tratamiento-datos table tr td:first-child {
  border-left: 0;
}

.tratamientos .tratamiento-datos table tr td:last-child {
  border-right: 0;
}

.tratamientos .tratamiento-datos tr:nth-child(even){
  background: rgba(240, 120, 192, 0.15);;
}

.ion-modal-tratamiento{
  --width: 90%;
  --height: 400px;
  --border-radius: 8px;
}

.ion-modal-tratamiento ion-content{
  --padding-start: 20px;
  --padding-end: 20px;
  --padding-top: 20px;
}

.ion-modal-tratamiento .header{
  color: var(--color-allivia-rosa);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}

.ion-modal-tratamiento .contenido{
  font-size: 14px;
}

.ion-modal-tratamiento .contenido ion-col{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ion-modal-tratamiento .contenido ion-radio{
  color: black;
}

.ion-modal-tratamiento .contenido ion-img{
  width: 30px;
  padding: 0 5px;
}

.ion-modal-tratamiento .contenido ion-item ion-input{
  width: 40%;
}

.ion-modal-tratamiento .contenido .panel-formulario .diag-input{
  padding-right: 0px;
}

.ion-modal-tratamiento .contenido .panel-formulario .add-col{
  padding-left: 0px;
  padding-right: 0px;
}
.ion-modal-tratamiento .contenido .panel-formulario .add-diagnostico::part(native){
  padding: 0px;
}

.ion-modal-tratamiento .header ion-img{
  width: 46px;
}

.ion-modal-tratamiento .header ion-segment{
  font-size: 14px;
}

.ion-modal-tratamiento .panel-documentos .upload-panel{
  border-radius: 10px;
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: #E8E8E8;
  margin-top: 20px;
  margin-bottom: 10px;
}

.ion-modal-diagnostico{
  --width: 90%;
  --height: 400px;
  --border-radius: 8px;
}

.ion-modal-diagnostico .header{
  color: var(--color-allivia-rosa);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}

.ion-modal-diagnostico ion-content{
  --padding-start: 20px;
  --padding-end: 20px;
  --padding-top: 20px;
}

.ion-modal-diagnostico .contenido .panel-formulario ion-item{
  --min-height: none;
  --padding-top:0px
}

.ion-modal-diagnostico .header ion-img{
  width: 46px;
}

.ion-modal-diagnostico .header ion-segment{
  font-size: 14px;
}
.ion-modal-eliminar-tratamiento{
  --width: 90%;
  --height: 200px;
  --border-radius: 8px;
}

.ion-modal-eliminar-tratamiento ion-content{
  --padding-start: 20px;
  --padding-end: 20px;
  --padding-top: 20px;
}

.ion-modal-eliminar-tratamiento .header{
  color: var(--color-allivia-rosa);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}

.ion-modal-eliminar-tratamiento .contenido{
  font-size: 14px;
}

.ion-modal-eliminar-tratamiento .contenido ion-col{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ion-modal-eliminar-tratamiento .contenido ion-col ion-button{  
  width: 100%;
}


/*IOS*/
.ios .datos_px {
  padding-top: 4%;
}

.ios #title_parentesco {
  margin-top: -9px;
}

.ios .header-nombre {
  font-size: .65em;
  color: #565656;
  font-weight: lighter;
}

.ios .header-parentesco {
  font-size: .65em;
  font-weight: lighter;
  --padding-top: -3px;
}

.ios .info_cabecera {
  display: inline-block;
  width: 100%;
  padding: 1% 0 0 1%;
  
}

.ios .titulos_menu {
  font-size: .85em;
}


.ios .trash {
  --margin: 0 13px 0 0;
}

.name_trat {
  font-size: .55em;
  --white-space: initial;
  text-overflow: ellipsis;
}

.ios .name_trat {
  font-size: .55em;
  --white-space: initial;
  text-overflow: ellipsis;
}

 #acciones_tratamientos {
   width: 96%;
   height: 50px;
   display: flex;
 }

.edit {
       width: 59px;
     }
/*rediseño*/

@media (min-width: 260px) and (max-width:279px) {


  .name_trat {
    font-size: .7em;
    text-align: center;
    text-transform: uppercase;
  }

}
@media (min-width: 280px) and (max-width:300px) {

  .name_trat {
    font-size: .7em;
    text-align: center;
    text-transform: uppercase;
  }

}
@media (min-width: 301px) and (max-width:319px) {

 
  .name_trat {
    font-size: .7em;
    text-align: center;
    text-transform: uppercase;
  }

}

@media (min-width: 320px) and (max-width:340px) {


  .name_trat {
    font-size: .7em;
    text-align: center;
    text-transform: uppercase;
  }

}

@media (min-width: 341px) and (max-width:359px) {

 
  .name_trat {
    font-size: .7em;
    text-align: center;
    text-transform: uppercase;
  }

}

@media (min-width: 360px) and (max-width:389px) {

 

  .name_trat {
    font-size: .7em;
    text-align: center;
    text-transform: uppercase;
  }

}
@media (min-width: 390px) and (max-width:409px) {

 
  .name_trat {
    font-size: .7em;
    text-align: center;
    text-transform: uppercase;
  }

}
@media (min-width: 410px) and (max-width:420px) {

 

  .name_trat {
    font-size: .7em;
    text-align: center;
    text-transform: uppercase;
  }

}

@media (min-width: 421px) and (max-width:439px) {

 
  .name_trat {
    font-size: .7em;
    text-align: center;
    text-transform: uppercase;
  }

}
@media (min-width: 440px) and (max-width:459px) {


  .name_trat {
    font-size: .7em;
    text-align: center;
    text-transform: uppercase;
  }

}

@media (min-width: 460px) and (max-width:780px) {

  .name_trat {
    font-size: .7em;
    text-align: center;
    text-transform: uppercase;
  }

}