
.signos-paciente ion-list{
  --padding:0;
}

.naranja{
  color: var(--naranja-terc);
  font-weight: bold;
}


.signos-paciente ion-img{
  width: 35px;
  margin-left: 50px;
  margin-inline: 20px;
}

.iconionpr{
  margin-right: 6px;
}

.signos-paciente ion-item{
  margin-top: 20px;
}

.signos-paciente ion-list-header{ 
  background: rgba(240, 120, 192, 0.3);
}

.signos-paciente ion-list-header ion-img{
  width: 35px; 
}

.signos-paciente .header-nombre{
  font-size: 16px;
  line-height: 18px;
  color: #565656;
  text-transform: capitalize;
}

.signos-paciente .header-parentesco{
  font-size: 16px;
  line-height: 18px;
  color: #565656;
  text-transform: capitalize;
}


.signos-paciente .info-panel{   
   padding:8% 5% 5% 5%;
   width: 100%;
   min-height:70vh;
}

.signos-paciente .signo-titulo{
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 500;
  font-size: 1em;
  line-height: 21px;
  text-align: center;
  color: #565656;
}

.signos-paciente .signo-titulo ion-img{
  width: 50px;
}

.signos-paciente .signos-datos table{
  width: 100%;  
  margin:2% 0;
}

.signos-paciente .signos-datos thead{
  background: rgba(240, 120, 192, 0.4);
  font-family: Ubuntu;
  font-style: normal;
  font-weight: 500;
  font-size:.7em;
  line-height: 14px;
}

.signos-paciente .signos-datos tbody{
  font-size:.7em;
  line-height: 14px;
}

.signos-paciente .signos-datos table td {
  border-left: 1px solid #565656; 
  border-right: 1px solid #565656;
}



.signos-paciente .signos-datos table tr td:first-child {
  border-left: 0;
}

.signos-paciente .signos-datos table tr td:last-child {
  border-right: 0;
}

.signos-paciente .signos-datos tr:nth-child(even){
  background: rgba(240, 120, 192, 0.15);;
}

.signos-paciente .signos-datos .panel-acciones{  
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content:space-evenly;
  border-left: 0;
}
.signos-paciente .signos-datos .panel-acciones ion-button::part(native){      
  padding: 0px;
}


.ion-modal-signos{
  --width: 90%;
  --height: 300px;
  --border-radius: 8px;
}

.presion{
  --height: 500px !important;
}

.ion-modal-signos ion-content{
  --padding-start: 20px;
  --padding-end: 20px;
  --padding-top: 20px;
}

.ion-modal-signos .header{
  color: var(--color-allivia-rosa);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}

.ion-modal-signos .contenido{
  font-size: 14px;
}

.ion-modal-signos .contenido ion-col{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ion-modal-signos .contenido ion-radio{
  color: black;
}

.ion-modal-signos .contenido ion-img{
  width: 20px;
}

.ion-modal-signos .contenido ion-item ion-input{
  width: 40%;
}

.ion-modal-signos .header ion-img{
  width: 46px;
}

.ion-modal-signos .header ion-segment{
  font-size: 14px;
}

.ion-modal-signos .panel-documentos .upload-panel{
  border-radius: 10px;
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: #E8E8E8;
  margin-top: 20px;
  margin-bottom: 10px;
}

.signos-chart{  
  width: 100%;
  min-height: 200px;
  display: inline-block;
}

.signos-chart text{  
  fill: #565656;
  font-size:.8em;
}

/*gráficas*/

#datos_signos{
  margin:8%;
}

.recharts-wrapper{
  padding:5% 0;
}
.recharts-surface{
  margin-left: -42px;
}

/*tabla datos*/
.header_datos th{
  padding:2% 0;
}
@media (min-width: 280px) and (max-width:400px) {
  
  .swiper-pagination {
      top: 303px;
      z-index: -1;
    }

    .clasi_oxi {
      width: 100%;
      display: block;
      margin-top: 20%;
    }

    .categoriaox {
      width: 100%;
    }

    .datos_clas {
      width: 100%;
      display: flow-root;
    }
        .icon_clas {
          width: 50%;
          margin: 10% 10px;
        }
}
    @media (min-width:400px) and (max-width:539px) {

      .swiper-pagination {
        top: 325px;
        z-index: -1;
      }
            /*Oxigenación*/
      
            .clasi_oxi {
              width: 100%;
              display: flex;
              margin-top: 20%;
            }
      
            .categoriaox {
              width: 50%;
              display: flow-root;
            }
      
            .datos_clas {
              width: 100%;
              display: flow-root;
            }
                        /*iconos presi´çon*/
                        .icon_clas {
                          width: 50%;
                          margin: 10% -1px;
                        }
            
                        .est_pressure {
                          --display: block;
                          --margin: 0px auto;
                        }

}
@media (min-width: 540px) and (max-width:1920px) {
    .swiper-pagination{
      top: 365px;
      z-index: -1;
    }
}

.header{
  font-size:.7em;
  text-align: center;
}

.header ion-img{
  width:40px;
  padding: 2%;
}


.icon{
  display:table-cell;
}
.icon ion-img{
  width:25px;
}
.txt_clas{
  display:table-cell;
  font-weight: bold;
  font-size:.8em;
}
.range{
  font-size: .7em;
  text-align: left;
  color: var(--text-secondary);
}
.emergenciah{
  font-size: .7em;
    text-align: left;
  color: var(--red-emergencia);
}
/*Arterial*/
.signos-datos{
  width: 100%;
  margin: 20% 0;
  text-align: center;
}
#contenedor_arterial{
  width:100%;
  display: inline-block;
}

#data_date{
  width:100%;
  background: var(--color-allivia-rosa);
  padding:3%;
  display: flow-root;
}
.header_row{
  display:flow-root;
}
.hour{
  width:50%;
  float:left;
  font-size:.8em;
  text-align: right;
  color: #fff;
}
.fecha {
  font-size: .8em;
  font-weight: bold;
  text-align: left;
  width: 50%;
  float: left;
  color:#fff;
}
#data_pressure{
  display:block;
  width:100%;
  background: #f5f5f5;
}
.date_pacie{
 width:80%;
 float:left;
}
.icon_pressure{
  width: 20%;
  float: left;
}
.left .rigth{
  width:100%;
  display:inline-block;
}
.icon_arm {
  width:30%;
  float:left;
  padding:3% 2%;
}
.sistolica {
  width: 30%;
  float: left;
  padding: 0 1%;
}
.sistolica p{
  text-align: center;
}
.distolica p {
  text-align: center;
}

.distolica {
  width: 30%;
  float: left;
  padding: 0 1%;
}
.titles_press{
  font-size:.8em;
  font-weight: bold;
  text-align: center;
}
.titles_press p{
  --text-align:center;
}
.date_sis{
  font-size:.7em;
  text-align: center;
}
.date_dis {
  font-size: .7em;
  text-align: center;
  padding: 0 0 3% 0;
}

/*IOS*/
.ios .datos_px {
  padding-top: 4%;
}

.ios #title_parentesco {
  margin-top: -9px;
}

.ios .header-nombre {
  font-size: .65em;
  color: #565656;
  font-weight: lighter;
}

.ios .header-parentesco {
  font-size: .65em;
  font-weight: lighter;
  --padding-top: -3px;
}

.ios .info_cabecera {
  display: inline-block;
  width: 100%;
  padding: 1% 0 0 1%;
  ;
}

.ios .titulos_menu {
  font-size: .85em;
}