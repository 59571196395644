
#titulo_pago h1{
  color:#65C8D0;
  font-variant: small-caps;
  text-align: center;
  font-weight: bold;
}
.metodo-pago{
  width:100%;
}
.metodo-pago ion-img{
  padding: 0 5%;
  width: 50px;
}
.metodo-pago ion-label{
  font-variant:small-caps;
  text-align: center;
}

.item-inner{
  border-color:#fff!important;
}