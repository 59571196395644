.registro-aviso-text{
  color: #686868;  
}

.registro-form ion-label{
  color: #999999;
}

.registro-slogan{
  text-align: center;  
}
.md header-md header-collapse-none{
  padding: 0 4%;
}

#title_return{
  font-size:1.1em;
  text-align: center;
}
.toolbar-title-default {
  padding:0 6%;
}

.header-md::after{
  background-image: none!important;
}

.header-ios ion-toolbar:last-of-type {
  --border-width: 0px !important;  
}

.registro-aviso-text{
  font-size:1.5em;
  padding: 0 0 0 3%;
  text-align: left;
}

.id_registro{
  width: 100%;
  height: 65px;
  margin:10px 3px 6px;
  font-size:1.4em;
  color:#000;
}
.id_registro::first-letter{
  text-transform: uppercase;
}
.apellidos{
  word-spacing: -0.5em;
    
}

.native-input div{
  height: 65px!important;
}

.registro_terminos{
  margin-top:2%;
}
.centrar_toogle{
  margin:2% 5%;
  width: 15%;
}
ion-button{
  font-size:1.3em;
}