h5{
  color:var(--text-secondary);
}

.premium-oferta{
  background-color: green;
  color: #FFFFFF;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.premium_oferta {
  width:100%;
  display: inline-block;
}

.membresias{
  width:40%;
  float:left;
  margin:0 5%;
}
#mensual{
  background: var(--fondo-mem-gray);
  padding:6%;
}
#mensual p{
  text-align: center;
  font-weight: bold;
  color:var(--text-secondary);
}
#anual{
  background: var(--naranja-terc);
  padding: 3%;
}

#anual p{
  text-align: center;
  font-weight: bold;
  color: var(--blanco);
  font-size:1.1em;
}
#textos_membresia{
  width:100%;
  margin-top:8%;
}

.frasemembresia{
  color: var(--naranja-terc);
  font-size:1em;
  font-weight: bold;
  text-align: center;
}
.descubre{
  text-align:center;
  font-size:.9em;

}

.sus_premium {
  margin-top:5%;
  border-radius: 10px;
  height: 50px;
  font-weight: bold;
  font-size: 1.3em;
  font-variant:small-caps;
  color:var(--blanco);
  /*--background: #FF6B00;*/
  --background:#439800;
}


/*título*/

.apremium{
  color:#65c8d0;
  font-size:1.2em;
  font-family: 'alliviaFont';
  font-weight: bold;
  /* padding: 0 3%;*/
}

.premiumm{
  color:#D29700;
  font-size:1.2em;
  font-weight: bold;
  
}

.beneficios{
  padding:5% 0;
  font-weight: bold;
}
.beneficios li{
  color: var(--text-secondary) !important;
}


#contenedor_premium ol li{
  margin:8% 0;
}


/*Soy allivia premium*/

#invitados_premium{
  width:100%;
  background: #F2F2F2;
}
.f_titulo{
  background-color: var(--aqua-main);
  padding:1%;
}

.f_titulo h6{
  color:#FFFFFF;
  font-variant: small-caps;
  font-weight: bold;
}
.invitadopremium{
  padding:4%;
  text-align: justify;
}