.allivia-menu ion-title{
  color: white;
}

ion-fab-button {
  --box-shadow: 0px 0px 0px 7px #ffffff;
} 

.allivia-caregivers {  
  padding-top: 10px;
  color: var(--color-allivia);
  font-family: caregiversFont;
  font-style: normal;
  font-weight: normal;
  font-size: 1.5rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  /* background-color: var(--color-allivia); */
}