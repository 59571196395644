
.acceso-compartido .paciente{    
  display: flex;
  font-weight: 500;
  font-size: 14px;
}

.acceso-compartido .nombre-completo{
  text-transform: capitalize;
}

.acceso-compartido .parentesco{
  color: var(--color-allivia-rosa);
}

.acceso-compartido h1{
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  color: #565656
}

.acceso-compartido .mensaje-compartir{
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;

}

.acceso-compartido .allivia{
  color: var(--color-allivia);
}

.acceso-compartido .premium{
  color: var(--color-allivia-dorado);
}

.ion-modal-correo{
  --width: 90%;
  --height: 500px;
  --border-radius: 8px;
}

.ion-modal-correo .contenido{
  padding-left: 30px;
  padding-right: 30px;
}

.ion-modal-compartir-paciente{
  --width: 90%;
  --height: 80%;
  --border-radius: 8px;
}

.ion-modal-compartir-paciente .contenido{
  padding-left: 30px;
  padding-right: 30px;
}

.ion-modal-compartir-paciente .contenido .card-paciente{
  display: flex;
  border: solid 2px;
  border-color: var(--color-allivia);
  margin-bottom: 30px;
  flex-direction: column;
  padding:5%;
  /* align-items: center; */
  /* align-content: center; */
  /* justify-content: center; */
  /* justify-items: center; */
}

.ion-modal-compartir-paciente .contenido .card-paciente .avatar{  
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.ion-modal-compartir-paciente .contenido .card-paciente .avatar .izq{  
  width: 90%;
  display: flex;
  justify-content: center;
}

.ion-modal-compartir-paciente .contenido .card-paciente .avatar .der{  
  width: 10%;
  text-align: right;
}

.ion-modal-compartir-paciente .contenido .card-paciente .avatar .der ion-checkbox{
  --border-radius: 12px !important;
  --border-color: var(--color-allivia-rosa);
  --background-checked: var(--color-allivia-rosa);
  --border-color-checked: var(--color-allivia-rosa);
  width: 25px;
  height: 25px;
}

.ion-modal-compartir-paciente .contenido .card-paciente ion-avatar{  
  width: 40px;
  height: 40px;
  transform: translate(40%);
}

.ion-modal-compartir-paciente .contenido .card-paciente .paciente{
  display: flex;
  flex-direction: row;
  /* align-content: center; */
  justify-content: center;
}

.ion-modal-compartir-paciente .contenido .card-paciente .paciente .paciente-datos .nombre-completo{
  text-align: right;
}

.ion-modal-compartir-paciente .contenido .card-paciente .paciente .paciente-datos .edad{
  text-align: right;  
}
.ion-modal-compartir-paciente .contenido .card-paciente .paciente .paciente-datos{
  width: 100%;
}

.ion-modal-compartir-paciente .contenido .card-paciente .paciente .parentesco{
  width: 30%;
}

.ion-modal-compartir-paciente .contenido .card-paciente .paciente .parentesco ion-label{
  color: var(--color-allivia-rosa);
  
}

.ion-modal-confirmacion{
  --width: 90%;
  --height: 360px;
  --border-radius: 8px;
}

.ion-modal-confirmacion .contenido{
  padding-left: 30px;
  padding-right: 30px;
}

.ion-modal-confirmacion .contenido .titulo{  
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  text-align: center;
  text-transform: uppercase;
  color: var(--color-allivia-rosa);
}

.ion-modal-confirmacion .contenido .titulo ion-col{
  padding-top: 20px;
  text-align: center;
  display: flex;
  justify-content: center;
}

.ion-modal-confirmacion .contenido .titulo ion-col ion-img{
  width: 40px;
}

.lista-invitados .paciente{    
  padding-left: 10px;
  display: flex;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
}

.lista-invitados ion-img{
  width: 40px;
  
}
.lista-invitados .nombre-completo{
  text-transform: uppercase;
}

.lista-invitados .paciente-aceptar{
  color: var(--color-allivia);
  text-transform: uppercase;
}

.lista-invitados .paciente-pacientes{
  text-transform: uppercase;
}

.ion-modal-acceso-paciente{
  --width: 90%;
  --height: 80%;
  --border-radius: 8px;
}

.ion-modal-acceso-paciente .contenido{
  padding-left: 30px;
  padding-right: 30px;
}

.ion-modal-acceso-paciente .contenido .card-paciente{
  display: flex;
  border: solid 2px;
  border-color: var(--color-allivia);
  margin-bottom: 5px;
  flex-direction: column;
  /* align-items: center; */
  /* align-content: center; */
  /* justify-content: center; */
  /* justify-items: center; */
}

.ion-modal-acceso-paciente .contenido .card-paciente .avatar{  
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.ion-modal-acceso-paciente .contenido .card-paciente .avatar .izq{  
  width: 90%;
  display: flex;
  justify-content: center;
}

.ion-modal-acceso-paciente .contenido .card-paciente .avatar .der{  
  width: 10%;
  text-align: right;
}

.ion-modal-acceso-paciente .contenido .card-paciente .avatar .der ion-checkbox{
  --border-radius: 12px !important;
  --border-color: var(--color-allivia-rosa);
  --background-checked: var(--color-allivia-rosa);
  --border-color-checked: var(--color-allivia-rosa);
  width: 25px;
  height: 25px;
}

.ion-modal-acceso-paciente .contenido .card-paciente ion-avatar{  
  width: 40px;
  height: 40px;
  transform: translate(40%);
}

.ion-modal-acceso-paciente .contenido .card-paciente .paciente{
  display: flex;
  flex-direction: row;
  /* align-content: center; */
  justify-content: center;
}

.ion-modal-acceso-paciente .contenido .card-paciente .paciente .paciente-datos .nombre-completo{
  text-align: right;
}

.ion-modal-acceso-paciente .contenido .card-paciente .paciente .paciente-datos .edad{
  text-align: right;  
}

.ion-modal-acceso-paciente .contenido .card-paciente .paciente .paciente-datos .estatus-invitacion{
  text-align: left;
}

.ion-modal-acceso-paciente .contenido .card-paciente .paciente .paciente-datos{
  width: 100%;
}

.ion-modal-acceso-paciente .contenido .card-paciente .paciente .parentesco{
  width: 30%;
}

.ion-modal-acceso-paciente .contenido .card-paciente .paciente .parentesco ion-label{
  color: var(--color-allivia-rosa);
  
}

.lista-invitados p{
  font-size:.9em;
  text-align: left;
}

#invitaciones{
  margin-bottom: 10%;
}

.title_contigo{
  font-variant: small-caps;
  font-weight: bold;
  text-align: left;
}

#invitar_mas{
  margin:30% 0;
}

.recuerda{
  color: var(--naranja-terc);
  font-variant: small-caps;
  font-size:1.2em;
}

