/*Agrega estilos de acuerdo a la plataforma web*/
@media (min-width: 900px) {
  
    body .login-page{
      display: flex;
      align-items: center;
      justify-content: center;
    }
  
    
    .login-page ion-list{
      width: 100%;
      height: 100px;    
    }
    .login-page .tel{
      padding: 10px;
    }
    .text-center{
      width:100%;
    }
    .text-center p{
      --text-align: center;
      --font-size: 1.2em;
    }

    /*home*/
   
    .ion-page{
      width:70%;
      margin:0 auto;
    }
        .custom-tab-bar ion-button div ion-icon {
          --font-size: 26px;
        }
    
    .menu-web{
     display: none; 
    }

    .allivia-menu{
      visibility: hidden;
    }

    .btn-menu{
      visibility: hidden;
    }
    #pacientes_compartidos h6{
      font-size:1.1em!important;
    }
    .paciente_compar{
      font-size:1.2em!important;
    }
 /*menú lateral web*/
    ion-title{
      color:#fff!important;
      font-size:1em!important;
    }

    ion-item{
      padding: 0% 0;
      margin:-1% 0;
    }
             .item.sc-ion-label-md-h,
             .item .sc-ion-label-md-h {
                font-size: 1em!important;
             }
     .item-native slot{
      font-size: 1.2em;
    }
        .toolbar-title-default {
          padding: 3% 6%;
        }

    /*Header allivia*/
    .alliviaweb{
        font-size: 2.8em !important;
        font-family:'alliviaFont';
        padding: 9% 0;
    }
        .home-title{
          display: none;
        }
    #avatar{
      min-height: 100px!important;
    }
        .sc-ion-buttons-md-s ion-button{
          height: 102px!important;
        }
    .homepage-iniciales {
      border-radius: 60px!important;
      width: 45px!important;
      height: 45px!important;
      font-size:1.5em!important;
    }

    /*HOME*/
    .panel-cuidador{
      font-size:1.5em;
    }
        .panel-pacientes ion-grid ion-avatar {
          height: 65px!important;
        }
        .panel-pacientes ion-grid img {
          width: 65px!important;
        }
    .titulos_opciones{
      font-size: 1.4em!important;
      line-height: initial;
      padding: 1.5% 0;
    }
    .fila_opciones{
      margin:-2% 0;
      padding: 2% 0;
    }
    #txt_cuido{
      font-size:1em!important;
    }

    .cuido-a ion-grid ion-avatar {
      height: 70px!important;
    }
    .cuido-a ion-grid img {
          width: 70px!important;
    }
    .paciente_parentesco{
      font-size:1.4em!important;
      line-height: initial;
      padding: 1.5% 0;
    }
        #pacientes_compartidos {
          padding: 2% 0!important;
        }
        .apremium  {
          font-size: 1.3em!important;
        }
        .intro_allivia{
          font-size:1.8em!important;
        }

    /*custombar*/
        .custom-tab-bar {
         padding:10px 0!important;
        }
        .custom-tab-bar ion-button div ion-icon {
           font-size: 35px!important;
        }
       
        /*Información personal*/
                ion-menu-toggle{
          display: none!important;
        }
        .menu-paciente ion-list-header {
         background: rgba(240, 120, 192, 0.7);
         height: 75px;
        }
        #contenedor_datos {
            width:70%!important;
            margin:0 auto;
        }
        #textos_allivia {
           width: 70%!important;
           margin: 0 auto;
         }
        #textos_allivia p{
           font-size: 1.3em !important;
        }
      #contenedor_opciones{
        width: 70% !important;
          margin: 0 auto;
      }
        .icon_px {
          width: 17%!important;
        }
        .menu-paciente ion-list-header ion-img {
          width: 44px!important;
        }
                .ios .datos_px {
                  padding-top: 7%!important;
                }
        .menu-paciente .header-nombre {
          font-size: 1em!important;
        }
        .menu-paciente .header-parentesco {
          font-size: .8em !important;
        }
        ion-ripple-effect {
          border-radius: 60px !important;
          width: 45px !important;
          height: 45px !important;
          font-size: 1.5em !important;
        }
      .menu-paciente ion-img {
          width: 80px!important;
             
      }
      .menu-paciente ion-item {
          margin-top: 55px!important;
        }

        /*Información personal*/
      #background-content{
        width:70%!important;
        margin: 0 auto!important;
      }
    .contenedor_avatar{
      margin-top:4%;
    }
    .textos_personal{
      font-size:1.4em!important;
    }
        .ios .titulos_menu {
          font-size: 1.2em!important;
        }
    .panel-paciente .btn-avatar {
          display: none !important;
    }
   .panel-paciente-form ion-accordion {
    background: none!important;
   }
     ion-grid{
      font-size:1.3em!important;
     }
  .panel-paciente-datos ion-label {
      font-size: 1.1em!important;
    }
  .photo{
    display: none!important;
  }
    .ion-modal-beneficiarios ion-label {
      font-size:1.3em!important;
    }
        .ion-modal-alergia ion-label {
        font-size: 1.3em !important;
         
        }
        .alert-radio-group.sc-ion-alert-md,
        .alert-checkbox-group.sc-ion-alert-md {
          max-height:100%!important;
          min-height: 400px!important;
          font: size 1.2em, im !important;
        }
        .sc-ion-alert-md-h {
          --min-width: 550px!important;
        }
                .panel-pacientes .cuido-a {
                  
                  font-size: 2.1em!important;
                }
        .msj-doc-noPremium{
          margin:4% 0;
          width:121%!important;
          display: inline-block;
        }
        .ico_small {
                  width: 22px!important;
        }
        /*modal*/
        .alert-tappable.sc-ion-alert-md {
          --font-size: 1.5em!important;
        }
                .panel-paciente-form ion-accordion .ds-aviso {
                  font-size: 1.2em!important;
                }
                                #aviso_privacidad {
                                 font-size:1em!important;
                                }
            
      /*Signos vitales*/
             #title_paciente .header-nombre {
               font-size: 1em !important;
             }
      
             #title_parentesco .header-parentesco {
               font-size: .8em !important;
             }
            .signos-paciente ion-list-header ion-img {
                 width: 45px;
             }
           .signos-paciente .signo-titulo ion-img {
                 width: 65px!important;
             }
            .signo-titulo p{
              font-size:1.1em;
              font-weight: bold;
            }
            .header_datos th{
              font-size:1.3em;
            }
            .signos-datos td{
              font-size:1.3em;
            }
            .button-inner{
              font-size:1em!important;
            }
            
            .swiper-slide {
              font-size:1.2em!important;
            }
            .recharts-wrapper {
               padding: 12% 0!important;
            }
           .txt_clas {
              font-size:1em!important;
            }
            .txt_clas p{
               font-size: 1em!important;
             }
            .icon ion-img {
               width: 32px!important;
            }
            .clasi_oxi{
              margin:10% 0;
            }
            .date_pacie {
              margin:3% 0;
            }
            .icon_pressure {
                margin: 3% 0;
              }
              .icon_arm ion-img {
                width: 50px!important;
                
            }
            .titles_press ion-img{
              margin-inline: 45px!important;
            }
            .icon_clas ion-img {
              margin-inline: 45px!important;
            }
            .titles_press p{
              font-size: 1.2em!important;
            }
            .date_sis .date_dis{
              font-size: 1.2em !important;
            }
                        .fecha .hour {
                          font-size:1.1em!important;
                          
                        }
            /*Estudios clínicos*/
            .estudios-titulo ion-img {
                width: 65px !important;
             }
            .estudios-titulo p {
              font-size: 1.1em;
              font-weight: bold;
             }
            .estudios-paciente .estudios-datos thead {
                 font-size: 1.3em;
                 height: 30px;
             }
            .estudios-datos th {
              font-size: 1.3em;
            }
            .estudios-datos td {
               font-size: 1.3em;
            }
            .editar {
              font-size: 1em !important;
            }

            /*Tratamientos*/
                        .tratamiento-titulo ion-img {
                          width: 65px !important;
                        }
            
                        .tratamiento-titulo p {
                          font-size: 1.1em;
                          font-weight: bold;
                        }
            #acciones_tratamientos{
              width:75%;
              position: absolute;
              margin:15% 13%;
              z-index: 999;
            }
            .edit {
              width: 70px !important;
             float: left;
            }
            .name_trat{
              font-size:1em!important;
            }

            /*Medicamentos*/
            .pastilla-medicamento ion-img {
               width: 65px !important;
            }
            .titulo_pantalla {
               font-size: 1.1em;
               font-weight: bold;
            }
            .medicamento .medicamento-panel .panel-diagnostico {
              font-size:1em!important;
              margin-top: 45px!important;
            }
            .mensaje-sin-registros{
              font-size:1.5em!important;
              text-align: center;
            }
            .medicamento .medicamento-datos thead {
                font-size:1em!important;
                height: 25px;
            }
            .medicamento .medicamento-datos table td {
               font-size: 1.3em !important;
            }
            /*Cita médica*/
            .cita-titulo ion-img {
                width: 65px !important;
            }
            .cita-titulo p {
                font-size: 1.1em;
                font-weight: bold;
            }
            .citaMedica .cita-datos {
              margin-top: 80px!important;
            
            }
            .cita-datos thead {
              font-size: 1.3em;
              height: 30px;
            }
            .titulos_cita th {
              font-size: 1.3em;
            }
            .cita-datos td {
              font-size: 1.3em;
            }
            /*Calendario*/
            .calendario-titulo ion-img {
               width: 65px !important;
             }
             .calendario-titulo p {
                font-size: 1.1em;
                font-weight: bold;
             }
             .rcb-headaer{
              font-size:100%!important;
             }
             /*Respiración*/
             .msg_respiracion{
              font-size:1.1em;
             }
             .respira{
              margin:10% 0;
             }
             /*Emocional*/
             .imagen ion-img {
                width: 65px !important;
             }
             .titulo h1 {
                font-size: 1.1em;
                font-weight: bold;
             }
             .nota{
              font-size:1.2em!important;
              line-height: 25px!important;
             }
             .felicitaciones{
              font-size:1.5em!important;
             }
             .contenido-preguntas{
                font-size:1.1em!important;

             }
             #sabias_que{
              font-size:1em!important;
             }
             .titulo span{
              font-size:1.3em!important;
             }
            .numero{
              font-size:1.2em!important;
              width:35px!important;
              height:35px!important;
              border-radius:30px!important;
              line-height:30px!important;
            }
            .header .titulo{
              font-size:1.3em!important;
              line-height: 20px!important;
            }
            .descripcion{
              font-size: .9em !important;
              line-height: 20px !important;
              hyphens: auto!important;
            }
            .contenido{
              font-size: 1.1em !important;
                line-height: 20px !important;
            }
           .cuestionario-uno .contenido .resultado .panel-mensaje .puntaje {
               border-radius: 22px!important;
           }
           .subtitulo .instrucciones{
            font-size: 1.1em !important;
           }
           .cuestionario-uno .contenido .cuestionario .respuestas {
              font-size: 13px!important;
              line-height: 21px!important;
           }

           .pregunta{
            font-size: 1em!important;
              line-height: 20px !important;
           }
           .panel-cuestionario {
            min-height: 330px!important;
          }
           .resultado{
            font-size:1.3em!important;
            line-height: 25px!important;
           }
           .resultado-descripcion{
            font-size:.9em!important;
            line-height: 25px!important;
           }

           /*Nutrición*/
          .textos_intro{
            font-size:1.1em!important;
          }
          .estado-nutricional .datos-generales .dg-header span {
            font-size:1.1em!important;
          }
          .estado-nutricional .datos-generales .contenido ion-label {
            font-size:1em!important;
            line-height: 25px!important;
          }
          .categoria{
            font-size:1.3em!important;
          }
          .categoria-preg{
            font-size: 1.2em !important;
            line-height: 25px !important;
          }
          .resp-radio .descripcion{
            font-size:1em!important;
          }

          /*Hidratación*/
          .hidratacion .contenido .titulo {
            font-size:1.3em!important;
                    }
          .mensaje p{
            font-size:1.1em!important;
          }
          .opPeso{
            font-size: 1.1em !important;
          }
          .hidratacion .formula .operacion div {
            flex:0.8!important;
          }
          .boton-agregar ion-button{
            height:60px!important;
          }
          .control-consumo-agua ion-fab-button {
            margin:0 65px 0 0!important;
          }
          /*Menú lateral pantallas*/
            .soy-premium-titulo{
              color: dimgray!important;
              padding: 0 1% !important;
            }
           .soy-premium-titulo .apremium {
               padding:0 1%!important;
            }
           .soy-premium-titulo .premiumm {
               padding: 0 1%!important;
           }
           .beneficios{
            font-size:1.1em!important;
           }

           .vigencia_suscri{
            color:#8a8a8a!important;
           }
           .info_baja{
            font-size:1.1em;
           }
           .info_baja a{
            font-weight: bold;
           }
           /*Derechos del cuidador*/
           .cuidador h2 {
            font-size:1.3em!important;
           }
           .cuidador p{
            font-size:1.1em!important;
            margin:8% 0;
           }
           .lista_derechos{
            font-size:1.2em!important;
            margin:9% 0 0 15%!important;
           }
           /*Acerca*/
           .acerca h1{
            font-size:1.1em;
           }
           .caregiver{
            font-size: 1.1em!important;
            line-height: 21px!important;
           }
          .acerca h3 {
            font-size: 1.1em!important;
          }
          .acerca p{
            font-size:1em!important;
            line-height: 20px !important;
          }
          /*Contacto y Soporte*/
          .txt{
            font-size:1.2em!important;
          }

}

  @media (max-width: 899px) {
    .menu-web{
      visibility: hidden;
    }
    
  }