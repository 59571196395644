.estado-emocional{
  padding-left: 20px;
  padding-right: 20px;
}

.estado-emocional .header{
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.estado-emocional .header .imagen{
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  
}

.estado-emocional .header .imagen span{
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 11px;
}

.estado-emocional .header .imagen ion-img{
  width: 61px;
}

.estado-emocional .header .titulo h1{
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  text-transform: uppercase;
  padding-left: 20px;
  padding-right: 20px;
  color: var(--color-allivia-rosa);
}

.estado-emocional .felicitaciones{
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  text-transform: uppercase;
  padding: 30px 10px 5px 10px;
  color: var(--color-allivia);
}

.estado-emocional{
  font-style: normal;
  font-size: 13px;
  line-height: 17px;
  color: #565656;
  text-align: justify;
  hyphens:auto;
}

.contenido-preguntas {
  font-style: normal;
  font-size: 13px;
  line-height: 17px;
  color: #565656;
  font-weight: bold;
}
.estado-emocional .contenido-preguntas ol{
  padding: 15px;
}

.estado-emocional .contenido-preguntas ol li{
  list-style-type: decimal !important;
  color: #565656;
}

.estado-emocional .contenido-preguntas .nota{
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 17px;
}

.estado-emocional .contenido-autovaloracion{
  padding-top: 20px;
}

.estado-emocional .contenido-autovaloracion .titulo span{
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  text-align: justify;
  text-transform: uppercase;
}

.panel-cuestionario {
  background: rgba(235, 235, 235, .7);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding: 10px;
  min-height:280px;
}
.panel-cuestionario {
  opacity: 1;
}

.panel-cuestionario .header{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: left;
}

.panel-cuestionario .header{
  width: 100%;
}

.panel-cuestionario .header .imagen{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.panel-cuestionario .header .imagen .numero{  
  width: 20px;
  height: 20px;
  border-radius: 10px;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  color: #FFFFFF; 
  background: var(--color-allivia);  
}

.panel-cuestionario .header .imagen .img-header{ 
  width: 77px;
  margin-left: 5px;
}

.panel-cuestionario .header .titulo{
  padding-top: 19px;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 12px;  
  color: var(--color-allivia-rosa);
  text-align: left;
  hyphens: none;
}

.panel-cuestionario .descripcion{
  padding-top: 20px;
  font-style: normal;
  font-weight: 300;
  font-size: 9px;
  line-height: 9px;
  text-align: justify;
  color: #565656;

}

.panel-cuestionario .resultado{
  padding-top: 10px;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: var(--color-allivia-rosa);

}

.panel-cuestionario .resultado-descripcion{
  padding-top: 20px;
  font-style: normal;
  font-weight: 300;
  font-size: 9px;
  line-height: 9px;
  text-align: center;
  color: #565656;

}

.disabled-router-link{
  pointer-events: none;
  opacity: .50;
}

.finalizado{
  pointer-events: none;
}

#intro_mas{
  margin-top:15%;
}