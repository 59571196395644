.custom-tab-bar{
  background: var(--color-allivia);
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 6px;
  padding-bottom: 6px;
}

.custom-tab-bar ion-button{
  flex: 1 1 0%;
  color: white;
  text-transform: capitalize;
  --padding-start: unset;
  --padding-end: unset;
}

.custom-tab-bar ion-button div{
  display: flex ;
  flex-direction: column;
  align-items: center !important;
  justify-content: center;
}

.custom-tab-bar ion-button div ion-icon{
  font-size: 27px;
}

.custom-tab-bar ion-button div ion-label{
  font-size: 12px;  
}

