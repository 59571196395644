

.estudios-paciente ion-img{
  width: 60px;
  margin-left: 50px;
  margin-inline: 20px;
}


.estudios-paciente ion-item{
  margin-top: 20px;
}

.estudios-paciente ion-list-header{ 
  background: rgba(240, 120, 192, 0.3);
}

.estudios-paciente ion-list-header ion-img{
  width: 35px; 
}

.estudios-paciente .header-nombre{
  font-size: 16px;
  line-height: 18px;
  color: #565656;
  text-transform: capitalize;
}

.estudios-paciente .header-parentesco{
  font-size: 16px;
  line-height: 18px;
  color: #565656;
  text-transform: capitalize;
}


.estudios-paciente .estudios-panel{   
   padding: 30px;
   width: 100%;
}

.estudios-paciente .estudios-titulo{
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: #565656;
}

.estudios-paciente .estudios-titulo ion-img{
  width: 50px;
}

.estudios-paciente .estudios-datos{
  margin-top: 40px;
}

.estudios-paciente .estudios-datos table{
  width: 100%;  
}

.estudios-paciente .estudios-datos thead{
  background: rgba(240, 120, 192, 0.4);
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
}

.estudios-paciente .estudios-datos tbody{
  font-size: 12px;
  line-height: 14px;
}

.estudios-paciente .estudios-datos table td {
  border-left: 1px solid black; 
  border-right: 1px solid black; 
}



.estudios-paciente .estudios-datos table tr td:first-child {
  border-left: 0;
}

.estudios-paciente .estudios-datos table tr td:last-child {
  border-right: 0;
}

.estudios-paciente .estudios-datos tr:nth-child(even){
  background: rgba(240, 120, 192, 0.15);;
}

.estudios-paciente .estudios-datos .panel-acciones{  
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content:center;
  border-left: 0;
}
.estudios-paciente .estudios-datos .panel-acciones ion-button::part(native){      
  padding: 0px;
}

.ion-modal-estudios{
  --width: 90%;
  --height: 550px;
  --border-radius: 8px;
}

.ion-modal-estudios ion-content{
  --padding-start: 20px;
  --padding-end: 20px;
  --padding-top: 20px;
}

.ion-modal-estudios .header{
  color: var(--color-allivia-rosa);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}

.ion-modal-estudios .contenido{
  font-size: 14px;
}

.ion-modal-estudios .contenido ion-col{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ion-modal-estudios .contenido ion-radio{
  color: black;
}

.ion-modal-estudios .contenido ion-img{
  width: 20px;
  margin:0 5px;
}

.ion-modal-estudios .contenido ion-item ion-input{
  width: 40%;
}

.ion-modal-estudios .header ion-img{
  width: 46px;
}

.ion-modal-estudios .header ion-segment{
  font-size: 14px;
}

.ion-modal-estudios .panel-documentos .upload-panel{
  border-radius: 10px;
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #E8E8E8;
  margin-top: 0px;
  margin-bottom: 10px;
  color: #747474;
}


.ion-modal-estudios .panel-documentos .upload-panel .upload-panel-buttons{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.estudios-datos{
  text-align: center;
  font-size:.8em;
}

.guardar_orange{
  --background: #FF6B00;
}


/*IOS*/
.ios .datos_px {
  padding-top: 4%;
}

.ios #title_parentesco {
  margin-top: -9px;
}

.ios .header-nombre {
  font-size: .65em;
  color: #565656;
  font-weight: lighter;
}

.ios .header-parentesco {
  font-size: .65em;
  font-weight: lighter;
  --padding-top: -3px;
}

.ios .info_cabecera {
  display: inline-block;
  width: 100%;
  padding: 1% 0 0 1%;
  ;
}

.ios .titulos_menu {
  font-size: .85em;
}