.estado-nutricional{
  padding-left: 0px;
  padding-right: 0px;
}

.estado-nutricional .header{
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.estado-nutricional .header .imagen{
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  
}

.estado-nutricional .header .imagen span{
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 11px;
}

.estado-nutricional .header .imagen ion-img{
  width: 61px;
}

.estado-nutricional .header .titulo h1{
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  text-transform: uppercase;
  padding-left: 20px;
  padding-right: 20px;
  color: var(--color-allivia-rosa);
}

.estado-nutricional .datos-generales{
  background: #F1F1F1;
  border-radius: 10px;
  padding: 7px;
  margin-bottom: 30px;
  margin-top: 30px;
}

.estado-nutricional .datos-generales .dg-header{  
  text-align: left;
}

.estado-nutricional .datos-generales .dg-header span{
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;  
  color: var(--color-allivia);
  text-transform: uppercase;
}

.estado-nutricional .datos-generales .contenido ion-label{
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  /* or 215% */
  color: #000000;
}

.estado-nutricional .contenido .cuestionario{
  background: #F1F1F1;
  border-radius: 10px;
  margin-bottom: 30px;
}


.estado-nutricional .contenido .cuestionario .pregunta{
  background: var(--color-allivia);
  border-radius: 5px;
  padding: 5px;
  text-align: center;
}

.estado-nutricional .contenido .cuestionario .pregunta .categoria{
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #FFFFFF;
  padding: 10px;
}

.estado-nutricional .contenido .cuestionario .categoria-preg{
  font-style: normal;
  padding: 5px 30px 5px 30px;
  font-weight: 500;
  font-size: 1em;
  line-height: 14px;
  text-align: left;
  color: #000000;
}

.estado-nutricional .contenido .cuestionario .respuestas{
  display: flex;  
  flex-direction: column;
}

.estado-nutricional .contenido .cuestionario .respuestas .resp-radio{
  display: flex;  
  flex-direction: row;
  padding: 10px;
  width: 100%;
}

.estado-nutricional .contenido .cuestionario .respuestas .resp-radio{
  display: flex;  
  flex-direction: row;
  padding: 10px;
  width: 100%;
}

.estado-nutricional .contenido .cuestionario .respuestas .resp-radio .rdio{
  width: 20px;
}


.estado-nutricional .contenido .cuestionario .respuestas .resp-radio .descripcion{
  display: flex;  
  flex-direction: row;
  padding-left: 15px;
}
/*textos extras arriba*/

.textos_intro{
  margin-top:15%;
  display: inline-block;
  text-align: justify;
  font-size: .9em;
}

.test{
  background-color: red;
  width: 100%;
}
.notanutricion{
  font-weight: bold;
  font-variant: small-caps;
}