.info-paciente ion-img{
  width: 60px;
  margin-left: 50px;
  margin-inline: 20px;
}


.info-paciente ion-item{
  margin-top: 20px;
}

.info-paciente ion-list-header{
  display: flex;
  flex-direction: column;
  background: rgba(240, 120, 192, 0.1);
  text-align: center;   
}

.info-paciente ion-list-header ion-img{
  width: 85px;
  margin: 0;
  float: right;
}

.info-paciente .header-nombre{
  color: #000000;
  text-transform: capitalize;
}

.info-paciente .header-parentesco{
  color: var(--color-allivia-rosa);
  text-transform: capitalize;

}
#benef{
  --min-height: 39px;
}

.recordar_save{
  width:100%;
  text-align: center;
  font-size:.9em;
  font-variant:small-caps;

}

.name_beneficiarios input{
  min-height: 39px;
}
.icon_small{
  width: 10px;  
}
.panel-paciente{
  width: 100%;
  text-align: center;
}
.ico_small {
  width: 10px;
}

.capitalizar{
  text-transform: capitalize;
}
.listas_datos {
   --padding-start: 5px;

 }

.linea_urgencias{
  width:100%;
  height:3px;
  background:#65c8d0;
  margin-bottom: 5px;

} 
/*Estilos para IOS*/


 .ios .listas_datos {
  --padding-start: 5px;
  
}

#aviso_privacidad{
  padding:0 10px;
  font-size: .8em;
  color:var(--color-allivia-rosa);
}

.textos_descriptivos{
  font-size:.7em;
  font-weight: bold;
  color:var(--color-allivia-rosa);
  text-align: center;
}

.allivia-input-disabled input{
  opacity: 1 !important;
}