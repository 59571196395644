.menu-paciente ion-img{
  width: 60px;
  margin-left: 50px;
  margin-inline: 20px;
}


.menu-paciente ion-item{
  margin-top: 20px;
}

.menu-paciente ion-list-header{
  background: rgba(240, 120, 192, 0.7);
}

.menu-paciente ion-list-header ion-img{
  width: 35px;
}

.menu-paciente .header-nombre{
  color: #565656;
  text-transform: capitalize;
  font-size:1.1em;
}

.menu-paciente .header-parentesco{
  color: white;
  text-transform: capitalize;
}

/*Cambios menú paciente*/
.titulos_menu{
  font-size:.8!important;
  color:#565656!important;
}

.fila_menu{
  padding: 3% 0;
}

/*Cabecera paciente*/

#contenedor_datos{
  width:100%;
}

.icon_px {
  float:left;
  width:20%;
  padding: 6% 0;
}

.datos_px{
  width:80%;
  float: left;
  padding-top: 6%;
}

#title_paciente {
  width: 100%;
  display: block;
}

#title_parentesco {
  width: 100%;
  display: block;
}



/*IOS*/
.ios .datos_px {
  padding-top: 4%;
}

.ios #title_parentesco {
  margin-top: -9px;
}

.ios .header-nombre {
  font-size:.65em;
  color: #565656;
  font-weight: lighter;
}

.ios .header-parentesco{
  font-size: .65em;
  font-weight: lighter;
  --padding-top:-3px;
  color:#fff;
}

.ios .info_cabecera {
  display: inline-block;
  width:100%;
  padding: 1% 0 0 1%;;
}

.ios .titulos_menu{
  font-size:.85em;
}
/*modales a blancos*/

.ios .alert-wrapper.sc-ion-alert-ios{
  background: #fff;
}