   .ios .header-ios ion-toolbar:last-of-type {
       --background: #fff;
   }
/*IOS STATUS BAR*/
/*Iphone x*/
@media only screen and (device-width : 375px) and (device-height : 812px) and (-webkit-device-pixel-ratio : 3) {
.ios ion-header {
        margin-top: 13%;
        height: 55px;
    }

    .ios .custom-tab-bar {
        height: 85px;
    }

    .ios .menuinferior {
        height: 85px;
    }

    .ios .consumo-botella {
        background-size: 47% 100%;
    }

    /*invitación*/

    .ios .fila_invitacion {
        flex-basis: 550px;
    }
         .ios .apremium {
             margin-top: 15px;
         }
}



/*Iphone8*/
@media only screen and (device-width : 375px) and (device-height : 667px) and (-webkit-device-pixel-ratio : 2) {

    .ios ion-header {
            margin-top: 30px;
            height: 55px;
        }
    
        .ios .custom-tab-bar {
            height: 65px;
        }
    
        .ios .custom-tab-bar ion-button div ion-icon {
            font-size: 24px;
        }
        .ios .consumo-botella {
         background-size: 47% 100%;
         }
                /*IOS*/
        
                /*invitación*/
        
               .ios .fila_invitacion {
        
                    flex-basis: 250px;
                }
        .ios .apremium{
            margin-top:15px;
        }
}

/*Iphone 8Plus*/

@media only screen and (device-width : 414px) and (device-height : 736px) and (-webkit-device-pixel-ratio : 3) {
        .ios ion-header {
                margin-top: 13%;
                height: 55px;
            }
        
            .ios .custom-tab-bar {
                height: 85px;
            }
        
            .ios .menuinferior {
                height: 85px;
            }
        
            .ios .consumo-botella {
                background-size: 52% 100%;
            }
        
            /*invitación*/
        
            .ios .fila_invitacion {
                flex-basis: 550px;
            }

                         .ios .apremium {
                             margin-top: 15px;
                         }
}

 /* iphone XR, 11 */
@media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio : 2) {
    
    .ios ion-header {
            margin-top: 13%;
            height: 55px;
        }
    .ios .custom-tab-bar {
         height: 85px;
        }
    .ios .menuinferior {
        height: 85px;
    }
    .ios .consumo-botella {
            background-size: 52% 100%;
    }

     /*invitación*/
                
    .ios .fila_invitacion {
         flex-basis: 550px;
    }
         .ios .apremium {
             margin-top: 15px;
         }
}

/* iphone X , XS, 11 Pro, 12 Mini */

@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {

    .ios ion-header {
            margin-top: 13%;
            height: 55px;
        }
    
        .ios .custom-tab-bar {
            height: 85px;
        }
    
        .ios .menuinferior {
            height: 85px;
        }
                .ios .consumo-botella {
                    background-size: 47% 100%;
                }

                  /*invitación*/
        
                  .ios .fila_invitacion {
                      flex-basis: 550px;
                  }
                                   .ios .apremium {
                                       margin-top: 15px;
                                   }

 }
/* iphone XR, 11 */
@media only screen and (min-device-width : 414px) and (max-device-height : 896px) and (-webkit-device-pixel-ratio : 2) {
        .ios ion-header {
                margin-top: 13%;
                height: 55px;
            }
        
            .ios .custom-tab-bar {
                height: 85px;
            }
        
            .ios .menuinferior {
                height: 85px;
            }
        
            .ios .consumo-botella {
                background-size: 52% 100%;
            }
        
            /*invitación*/
        
            .ios .fila_invitacion {
                flex-basis: 550px;
            }
                         .ios .apremium {
                             margin-top: 15px;
                         }

}
 /* iphone XS Max, 11 Pro Max */
@media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) {
    .ios ion-header {
            margin-top: 13%;
            height: 55px;
        }
    
        .ios .custom-tab-bar {
            height: 85px;
        }
    
        .ios .menuinferior {
            height: 85px;
        }
                .ios .consumo-botella {
                    background-size: 52% 100%;
                }
                                 /*invitación*/
                
                                 .ios .fila_invitacion {
                                     flex-basis: 550px;
                                 }
                                                                  .ios .apremium {
                                                                      margin-top: 15px;
                                                                  }

}
 /* iphone X , XS, 11 Pro, 12 Mini */
@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {

    .ios ion-header {
            margin-top: 13%;
            height: 55px;
        }
    
        .ios .custom-tab-bar {
            height: 85px;
        }
    
        .ios .menuinferior {
            height: 85px;
        }
                .ios .consumo-botella {
                    background-size: 47% 100%;
                }
                                 /*invitación*/
                
                                 .ios .fila_invitacion {
                                     flex-basis: 550px;
                                 }
                                                                  .ios .apremium {
                                                                      margin-top: 15px;
                                                                 }
        .ios .edit {
            width: 45px;
            float: left;
        }
}
/* iphone 12, 12 Pro */
@media only screen and (min-device-width: 390px) and (max-device-height: 844px) and (-webkit-device-pixel-ratio: 3) {
        .ios ion-header {
                margin-top: 13%;
                height: 55px;
            }
        
            .ios .custom-tab-bar {
                height: 85px;
            }
        
            .ios .menuinferior {
                height: 85px;
            }
        
            .ios .consumo-botella {
                background-size: 57% 100%;
            }
        
            
        
            /*invitación*/
            .ios .fila_invitacion {
                flex-basis: 550px;
        
            }
        
            .ios .title_contigo {
                padding: -20px 0;
            }
                         .ios .apremium {
                             margin-top: 15px;
                         }

}

/* iphone 12 Pro Max */
@media only screen and (min-device-width : 428px) and (max-device-height : 926px) and (-webkit-device-pixel-ratio : 3) {
            .ios ion-header {
                    margin-top: 13%;
                    height: 55px;
                }
            
                .ios .custom-tab-bar {
                    height: 85px;
                }
            
                .ios .menuinferior {
                    height: 85px;
                }
            
                .ios .consumo-botella {
                    background-size: 60% 100%;
                }
            
                /*invitación*/
            
                .ios .fila_invitacion {
                    flex-basis: 550px;
                }
                                 .ios .apremium {
                                     margin-top: 15px;
                                 }

}
/*Iphpne 13 y 13 PRO*/

@media only screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) {
        .ios ion-header {
                margin-top: 13%;
                height: 55px;
            }
        
            .ios .custom-tab-bar {
                height: 85px;
            }
        
            .ios .menuinferior {
                height: 85px;
            }
            .ios .consumo-botella {
                background-size: 57% 100%;
            }
            .ios .header-ios ion-toolbar:last-of-type{
               --background-color: none;
            }
                         /*invitación*/
                         .ios .fila_invitacion {
                             flex-basis: 550px;
               
                            }
           .ios .title_contigo{
                    padding:20px 0;
            }
            .ios #invitaciones {
                margin-bottom: 3%;
            }
            .ios .edit {
                width: 45px;
                
            }
                         .ios .apremium {
                             margin-top: 15px;
                         }
            
}

/*Iphone 13 PRO MAX*/

@media only screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
        .ios ion-header {
                margin-top: 13%;
                height: 55px;
            }
        
            .ios .custom-tab-bar {
                height: 85px;
            }
        
            .ios .menuinferior {
                height: 85px;
            }
                        .ios .consumo-botella {
                            background-size:60% 100%;
                        }
                                                 /*invitación*/
                        
                                                 .ios .fila_invitacion {
                                                     flex-basis: 550px;
                                                 }
                                                                                                  .ios .apremium {
                                                                                                      margin-top: 15px;
                                                                                                  }
}

/*Ipad*/

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
        .ios ion-header {
                margin-top: 5%;
                height: 55px;
            }
        
            .ios .custom-tab-bar {
                height: 85px;
            }
        
            .ios .menuinferior {
                height: 85px;
            }
    
            .ios .consumo-botella {
                background-size: 65% 100%;
            }
                         /*invitación*/
            
                         .ios .fila_invitacion {
                             flex-basis: 550px;
                         }
                                                  .ios .apremium {
                                                      margin-top: 15px;
                                                  }

                      .ios #txt_premium p {
                           --font-size: 1.8em;
                        }

                      .ios  #textos_allivia p{
                            --font-size: 1.8em;
                        }
                    .ios .menu-paciente ion-list-header ion-img {
                                            --width: 45px;
                                        }
                                      .ios .menu-paciente .header-nombre {
                                           --font-size: 1.5em;
                                        }
                .edit {
                    width: 133px;
                    float: left;
                }
        
                .trash {
                    float: left;
                }
}