 #nocuenta {
  text-align:center;
  font-size:1.1em;
}
.txtcuenta {
  text-align: center;
  font-weight: bold;
  font-size: 1.1em;
}
.login-header{
  width:100%;
  height: 100px;
  display: flex;
}
.login_caregiver {
  margin-right:40px;
}

.restablecer {
  font-size: 1.5em;
  border-radius: 4px;
  font-variant: normal !important;
}
#botonIniciarSesion {
  color: #fff;
  background-color: #84D3D9 !important;
  border-radius: 4px;
  font-variant: normal!important;
  font-size:1.4em;
}
ion-button{
  height: 65px;
}


/*.login-logo{
  width: 200px;
  height: 112.5px;
  margin:50px 7px;
}*/
.tel {
  padding: 4%;
}
hr{
  background: black;
}

.login-emergencia-text div{
  font-weight: bold;
  font-size: 18px;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex; 
  width: 100%;
}

.login-terminoscondiciones-text{
  font-size: 1.3em;
}

.login-version{
  text-align: center;
  padding: .5rem;
}

.login-version ion-label{
  color: #A5A5A5;
  font-size: 10px;
  line-height: 11px;
}


@media (min-width: 280px) and (max-width:334px) {
  .login-logo {
    width: 200px;
    height: 112.5px;
    margin: 50px 7px;
  }

  .login-grid {
    font-family: Ubuntu;
    font-size: .8em !important;
    font-style: normal;
  }

  .restablecer {
    font-size: .75em;
  }

  .txtcuenta {
    font-size: .75em;
  }
}

@media (min-width: 335px) and (max-width:600px) {
  .login-logo {
    width: 200px;
    height: 112.5px;
    margin: 50px 7px;
  }

  .login-grid {
    font-family: Ubuntu;
    font-size: .8em!important;
    font-style: normal;
  }

  .restablecer {
    font-size: .9em;
  }

  .txtcuenta {
    font-size: .9em;
  }
}

.resaltarbutton{
  --color: var(--aqua-main);
  font-weight: bold;
  font-size:1.1em;
  text-decoration: underline;
}

/*Versión web*/
.login-grid{
  width:70%;
  margin:0 auto;
  padding-top:15%;
}

.labels_forms{
  font-size:1.8em;
}

.input_forms{
  width:100%;
  height: 65px;
  font-size:1.3em!important;
}
.fila_login{
  margin-top:5%;
}