.cuestionario-cuatro .header{
  padding: 30px 60px 30px 60px;
  width: 100%;  
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: center;
  background: rgba(240, 120, 192, 0.1);
}

.cuestionario-cuatro .header .numero{
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;  
}

.cuestionario-cuatro .contenido{
  padding: 10px 60px 10px 60px;
}

.cuestionario-cuatro .contenido .subtitulo{
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 17px;
  text-align: justify;
}

.cuestionario-cuatro .contenido .instrucciones{
  padding-top: 15px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  text-align: justify;
}

.cuestionario-cuatro .contenido .instrucciones span{
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 17px;
  text-align: justify;
}

.cuestionario-cuatro .header .numero span{
  width: 30px;
  height: 30px;
  border-radius: 15px;
  color: #FFFFFF;
  background: var(--color-allivia);
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 30px;
}

.cuestionario-cuatro .header .titulo{
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
}
.cuestionario-cuatro .header .titulo span{
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  text-transform: uppercase;
  
  color: var(--color-allivia-rosa);
}

.cuestionario-cuatro .contenido .cuestionario{
  padding-top: 20px;
}

.cuestionario-cuatro .contenido .cuestionario .pregunta{
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  text-align: justify;
  color: #565656;
  padding: 10px;
  background: rgba(240, 120, 192, 0.3);
}

.cuestionario-cuatro .contenido .cuestionario .pregunta .categoria{
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 17px;
  text-align: justify;
  color: #565656;
}

.cuestionario-cuatro .contenido .cuestionario .respuestas{  
  display: flex;
  flex-direction: row;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 11px;  
  box-shadow: inset -1px 0px 40px rgba(0, 0, 0, 0.08);
  margin-bottom: 5px;
}

.cuestionario-cuatro .contenido .cuestionario .respuestas .resp-radio{  
  padding-top: 5px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  
}

.cuestionario-cuatro .contenido .cuestionario .respuestas .resp-radio .descripcion{
  padding: 10px;
  text-align: center;
  width: 100%;
}

.cuestionario-cuatro .contenido .resultado .panel-mensaje{
  height:130px;
  left: 77px;
  top: 1170px;
  background: #DEDEDE80;
  border-radius: 40px 5px 5px 40px;
}

.cuestionario-cuatro .contenido .resultado .panel-mensaje {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.cuestionario-cuatro .contenido .resultado .panel-mensaje .nota{
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  padding-left: 15px;
  padding-right: 15px;
  text-align: justify;
  hyphens: auto;
}



.cuestionario-cuatro .contenido .resultado .panel-mensaje .puntaje{
  height: 125px;
  width: 125px;
  border-radius:50px;
  background: var(--color-allivia);
  color: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: center;
}



.cuestionario-cuatro .contenido .resultado .panel-mensaje .numero{
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 31px;
  text-align: center;
}

.cuestionario-cuatro .contenido .resultado .panel-mensaje .texto{
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 13px;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 20px;
}

.cuestionario-cuatro .contenido .boton-siguiente{
  text-align: right;
  padding: 10px;
}

.cuestionario-cuatro .contenido .boton-siguiente ion-button{
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  height: 100px;
}

.cuestionario-cuatro .contenido .boton-siguiente ion-button .muy-bien{
  color: var(--color-allivia-gris);
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 13px;  
  display: flex;
  flex-direction: column; 
  text-transform: uppercase;
  text-align: center;  
}

.cuestionario-cuatro .contenido .boton-siguiente ion-button .muy-bien span{
  color: var(--color-allivia-rosa);
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 13px;
  text-align: center;
  text-transform: uppercase;
}


.muy-bien {
  width:100%;
}

.muy-bien h4{
  color:var(--color-allivia-rosa);
  font-weight: bold;
}

.muy-bien{
  text-align: center;
  font-size:1em;
}

.resultado{
  margin:15% 0;
}
.resp-radio3{
  padding-top:0px;
  width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.descripcion3{
  padding: 13.2px;
  text-align: center;
  width: 100%;
}

.Test p{
  text-align: center;
}