@font-face {
    font-family: 'icomoon';
    src: url('../fonts/icomoon.eot?l5056r');
    src: url('../fonts/icomoon.eot?l5056r#iefix') format('embedded-opentype'),
        url('../fonts/icomoon.ttf?l5056r') format('truetype'),
        url('../fonts/icomoon.woff?l5056r') format('woff'),
        url('../fonts/icomoon.svg?l5056r#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@media (min-width: 280px) and (max-width:500px) {
    #icon {
        width: 100%;
        text-align: center;

    }

    #txtsuger {
        width: 100%;
    }


}

#contenedor_sugerencias{
    width:100%;
}
#titulo{
    width:100%;
    display: inline-block;
}
.titular{
    width:100%;

}
@media (min-width: 501px) and (max-width:1080px) {
#icon {
        width: 5%;
        float: left;
        margin: 3% 0 0 15%;

    }

    #txtsuger {
        width: 80%;
        float: left;
    }



}
   
.titular h2{
    font-weight: bold;
    text-align: center;
    color:#F078C0;
}
p{
    text-align: justify;
}
.bold{
    font-weight: bold;
    color:#65C8D0;
}
#formulario{
    width:100%;
}

.inputs{
    width:86%;
    padding: 1% 0;
    margin:1% 0;
}
#txtarea{
    /*border:1px solid #65C8D0;*/
    border-radius: 3px;
    background: rgba(196, 196, 196, 0.3);
    width:87%;
}
.native-input:first-letter{
    --text-transform: uppercase;
}
#txtarea:first-letter{
    text-transform: uppercase;
}

.enviar{
    width:86%;
    background-color:#65C8D0;
    color:#fff;
    text-align: center;
    font-size:1em;
    font-weight: bold;
    border:none;
    height:32px;
    border-radius: 8px;
}

#datos_cont{
    width:100%;
    margin:16% 0;
}
ul li{
    margin:5% 0;
    list-style: none!important;
}
.icon-iconos-01:before {
    content: "\e900";
    color: #e47bb1;
}

.icon-iconos-02:before {
    content: "\e901";
    color: #e47bb1;
}

.icon-iconos-03:before {
    content: "\e902";
    color: #e47bb1;
}

.icon-iconos-04:before {
    content: "\e903";
    color: #e47bb1;
}

.icon-iconos-05:before {
    content: "\e904";
    color: #e47bb1;
}

.icon-iconos-06 .path1:before {
    content: "\e905";
    color: rgb(228, 123, 177);
}

.icon-iconos-06 .path2:before {
    content: "\e906";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
a{
    text-decoration: none;
    color:black!important;
    cursor:pointer;
}

.contenedor_iconos{
    width:100%;
    min-height: 65px;
}
.iconos{
    width:13%;
    float:left;
    padding: 2%;
}
.txt{
    width:87%;
    float:left;
    padding: 2%;
    font-size:.9em;
}