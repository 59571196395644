.cuestionario-tres .header{
  padding: 30px 60px 30px 60px;
  width: 100%;  
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: center;
  background: rgba(240, 120, 192, 0.1);
}

.cuestionario-tres .header .numero{
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;  
}

.cuestionario-tres .contenido{
  padding: 10px 60px 10px 60px;
}

.cuestionario-tres .contenido .subtitulo{
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 17px;
  text-align: justify;
}

.cuestionario-tres .contenido .instrucciones{
  padding-top: 15px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  text-align: justify;
}

.cuestionario-tres .contenido .instrucciones span{
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 17px;
  text-align: justify;
}

.cuestionario-tres .header .numero span{
  width: 30px;
  height: 30px;
  border-radius: 15px;
  color: #FFFFFF;
  background: var(--color-allivia);
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 30px;
}

.cuestionario-tres .header .titulo{
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
}
.cuestionario-tres .header .titulo span{
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  text-transform: uppercase;
  
  color: var(--color-allivia-rosa);
}

.cuestionario-tres .contenido .cuestionario{
  padding-top: 20px;
}

.cuestionario-tres .contenido .cuestionario .pregunta{
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  text-align: justify;
  color: #565656;
  background: rgba(240, 120, 192, 0.3);
  padding: 10px;
}

.cuestionario-tres .contenido .cuestionario .pregunta .categoria{
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 17px;
  text-align: justify;
  color: #000000;
}

.cuestionario-tres .contenido .cuestionario .respuestas{
  padding-top: 10px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 11px;  
}

.cuestionario-tres .contenido .cuestionario .respuestas .resp-radio{  
  padding-top: 5px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.08);
  padding: 10px;
}

.cuestionario-tres .contenido .cuestionario .respuestas .resp-radio .descripcion{
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 20px;
  text-align: justify;
  width: 100%;
  font-weight: 500;
  font-size: 13px;
  line-height: 17px;
  color: #000000;
}

.cuestionario-tres .contenido .resultado .panel-mensaje{
  height: 130px;
  left: 77px;
  top: 1170px;
  background: #DEDEDE80;
  border-radius: 40px 5px 5px 40px;
}

.cuestionario-tres .contenido .resultado .panel-mensaje {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.cuestionario-tres .contenido .resultado .panel-mensaje .nota{
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  padding-left: 15px;
  padding-right: 15px;
  text-align: justify;
  hyphens: auto;
}



.cuestionario-tres .contenido .resultado .panel-mensaje .puntaje{
  height: 125px;
  width: 125px;
  border-radius: 50px;
  background: var(--color-allivia);
  color: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: center;
}



.cuestionario-tres .contenido .resultado .panel-mensaje .numero{
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 31px;
  text-align: center;
}

.cuestionario-tres .contenido .resultado .panel-mensaje .texto{
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 13px;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 20px;
}

.cuestionario-tres .contenido .boton-siguiente{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.cuestionario-tres .contenido .boton-siguiente ion-button{
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;  
  width: 100%;
}

/*flecha arriba*/

#boton_up {
  width:35px;
  height:35px;
  background-color: var(--color-allivia-rosa);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color:white;
  font-size:1em;
  font-weight: bold;
  position:fixed;
  bottom: 23px;
  right: 15px;
  cursor: pointer;
  border:4px solid transparent;
  transition: all 300ms ease;
  transform: scale(0);
}

#buton_up:hover{
  transform: scale(1.5);
  border-color: rgba(0,0,0,0.1);
}