
.soy-premium{
  --background: #F2F2F2;
}
/*título*/
.soy-premium-titulo .apremium{
  color:#65c8d0;
  font-size:1.2em;
  font-family: 'alliviaFont';
  font-weight: bold;
  padding: 0 3%;
}

.soy-premium-titulo .premiumm{
  color:#D29700;
  font-size:1.2em;
  font-weight: bold;
  padding:0 3%;
}

.soy-premium .beneficios h3{
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: var(--text-secondary)
}

.soy-premium .beneficios{ 
  padding-top: 16px;
  padding-right: 40px;
  padding-bottom: 16px;
  padding-left: 40px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: var(--text-secondary);
  background: #FFFFFF;

}

/*Soy allivia premium*/
.invitados-prem{
  width:100%;
  background: #F2F2F2;
}

.invitados-prem .ip_titulo{
  background-color: var(--color-allivia);
  padding:1%;
  padding-left: 40px;
}

.invitados-prem .ip_titulo h6{
  color:#FFFFFF;
  font-weight: bold;
}
.invitados-prem .msj-inv-prem{
  padding:4%;
  padding-left: 40px;
  padding-right: 40px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #000000;  
}

.invitados-prem .msj-inv-prem .tallivia{
  color:#65c8d0; 
}

.invitados-prem .msj-inv-prem .tpremium{
  color:#D29700;
  font-weight: bold;
}

.invitados-prem .l-sin-invitados{
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 40px;
  padding-right: 40px;
}

.invitados-prem .l-invitados{
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 40px;
  padding-right: 40px;
}

.invitados-prem .l-invitados .mensaje{
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}

.invitados-prem .l-invitados .mensaje-p{  
  color:#D29700;
  font-weight: bold;
  text-transform: uppercase;
}

.invitados-prem .l-invitados .invitado{  
  display: flex;
  flex-direction: row;
  align-items: center;
}
.invitados-prem .l-invitados .invitado .im-inv{  
  background: var(--color-allivia-rosa);
  width: 54px;
  height: 54px;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  text-transform: uppercase;  
  color: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.invitados-prem .l-invitados .invitado .datos-inv{
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  text-transform: uppercase;
  width: calc(100% - 60px);
}

.invitados-prem .l-invitados .invitado .datos-inv .datos-estatus{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  text-transform: uppercase;
}

.invitados-prem .l-invitados .invitado .datos-inv .datos-estatus .estatus{
  color: var(--color-allivia);
  width:100%;
  margin: 0px;
  padding: 0px;
}


.invitados-prem .l-invitados .invitado .accion{
  --color: var(--color-allivia-rosa);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  align-content: flex-end;
  height: 100%;
}

.invitados-prem .l-invitados .invitado .accion ion-button{
  --color: var(--color-allivia-rosa);
  width: 20px;
  height: 20px;
  flex: fit-content;
  padding-top: 25px;
}

.invitados-prem .l-invitados .invitado .accion ion-button::part(native){
  padding: 0px
}



.invitados-prem .panel-agregar ion-item{
 --background: none;
 --padding-start: 36px
}

.invitados-prem .panel-agregar ion-img{
  width: 29px;
}

.invitados-prem .panel-agregar ion-label{
  padding-left: 20px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height */
  text-transform: uppercase;
  /* Textos */
  color: #565656;
}

.modal-correo-invitado{
  --width: 90%;
  --height: 500px;
}

.modal-correo-invitado .header{
  padding: 20px 10%;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  /* Textos */
  color: #565656;
  text-align: justify;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.modal-correo-invitado .header ion-label{
  width: 100%; 
 }

.modal-correo-invitado .header ion-button{
 width: 20px; 
}

.modal-correo-invitado .header ion-button::part(native){      
  padding: 0px;
}

.modal-correo-invitado .contenido{
  padding: 0 10%;
}

.modal-correo-invitado .contenido .instrucciones{
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  /* Textos */
  color: #565656;
}

.modal-correo-invitado .contenido .mensaje{
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  /* Textos */
  color: #565656;
}

.modal-correo-invitado .contenido .mensaje .w-importante{
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  /* Textos */
  color: #565656;
}

.modal-correo-confirmacion{
  --width: 90%;
  --height: 250px;
  
}

.modal-correo-confirmacion .contenido{
  padding: 10% 10%;  
}

.modal-correo-confirmacion .contenido .usuario{
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;

  /* Pink bueno */

  color: var(--color-allivia-rosa);

}

.modal-correo-confirmacion .contenido .mensaje{
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  /* Textos */
  color: #565656;
}

.modal-correo-confirmacion .contenido .mensaje1{
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  /* Textos */
  color: var(--color-allivia-rosa);
  text-align: center;
}

.modal-correo-confirmacion .contenido .mensaje2{
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  /* Textos */
  color: #565656;
  text-align: center;
}

.modal-correo-confirmacion .contenido .mensaje .w-premium{
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  /* Textos */
  color:#D29700;
}

.modal-correo-confirmacion .contenido .mensaje2 .w-allivia{
  font-family: 'alliviaFont';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  /* Textos */
  color: var(--color-allivia);
}

.modal-correo-confirmacion .contenido .mensaje3 {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;

  /* Pink bueno */

  color: var(--color-allivia-rosa);
}

.modal-correo-confirmacion .contenido .mensaje4{
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  /* Textos */
  color: #565656;
}

.modal-correo-confirmacion .contenido .mensaje4 .w-premium{
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  /* Textos */
  color:#D29700;
}

.modal-eliminar-invitado{
  --width: 90%;
  --height: 350px;
}

.modal-eliminar-invitado .contenido{
  padding: 10% 10%;  
}

.modal-eliminar-invitado .imagen{
  width: 100%;
}
.modal-eliminar-invitado .imagen ion-img{
  width: 55px;
  align-items: center;
  margin: auto;
}

.modal-eliminar-invitado .mensaje1{
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  color: #565656;
  text-align: center;
  padding-top: 20px;
}

.modal-eliminar-invitado .mensaje2{
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #565656;
  text-align: justify;
  padding-top: 20px;
}

.modal-eliminar-invitado .mensaje3{
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #565656;
  text-align: justify;
  padding-top: 20px;
}

.modal-eliminar-invitado .botones{
  padding-top: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;  
}

.modal-eliminar-invitado .botones .dl{
  width: 100%;
  border-right: 1px solid var(--color-allivia-rosa);
  border-top: 2px solid var(--color-allivia-rosa) ;
}

.modal-eliminar-invitado .botones .dr{
  width: 100%;
  border-left: 1px solid var(--color-allivia-rosa);
  border-top: 2px solid var(--color-allivia-rosa) ;
}

.modal-eliminar-invitado .botones ion-button{
  width: 100%;
  --color: var(--color-allivia-rosa)
}
