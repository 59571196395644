.cuestionario-dos .header{
  padding: 30px 60px 30px 60px;
  width: 100%;  
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: center;
  background: rgba(240, 120, 192, 0.1);
}

.cuestionario-dos .header .numero{
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;  
}

.cuestionario-dos .contenido{
  padding: 10px 60px 10px 60px;
}

.cuestionario-dos .contenido .subtitulo{
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 17px;
  text-align: justify;
}

.cuestionario-dos .contenido .instrucciones{
  padding-top: 15px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  text-align: justify;
}

.cuestionario-dos .contenido .instrucciones span{
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 17px;
  text-align: justify;
}

.cuestionario-dos .header .numero span{
  width: 30px;
  height: 30px;
  border-radius: 15px;
  color: #FFFFFF;
  background: var(--color-allivia);
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 30px;
}

.cuestionario-dos .header .titulo{
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
}
.cuestionario-dos .header .titulo span{
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  text-transform: uppercase;
  
  color: var(--color-allivia-rosa);
}

.cuestionario-dos .contenido .cuestionario{
  padding-top: 20px;
}

.cuestionario-dos .contenido .cuestionario .pregunta{
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  text-align: justify;
  color: #565656;
  background: rgba(222, 222, 222, 0.3);
  padding: 16px 8px 16px 8px;
}

.cuestionario-dos .contenido .cuestionario .pregunta .categoria{
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 17px;
  text-align: justify;
  color: #565656;
  
}

.cuestionario-dos .contenido .cuestionario .respuestas{
  padding-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 11px;
}

.cuestionario-dos .contenido .cuestionario .respuestas .resp-radio{  
  padding: 8px 1px;  
  width: 100%;  
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.08), inset -1px 0px 0px rgba(17, 17, 17, 0.08);
}

.cuestionario-dos .contenido .cuestionario .respuestas .resp-radio .descripcion{
  padding-top: 5px;
  padding-bottom: 5px;
  background: rgba(244, 244, 244);
  text-align: center;
  width: 100%;
}

.cuestionario-dos .contenido .resultado .panel-mensaje{
  height: 130px;
  left: 77px;
  top: 1170px;
  background: #DEDEDE80;
  border-radius: 40px 5px 5px 40px;
}

.cuestionario-dos .contenido .resultado .panel-mensaje {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.cuestionario-dos .contenido .resultado .panel-mensaje .nota{
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  padding-left: 15px;
  padding-right: 15px;
  text-align: justify;
  hyphens: auto;
}



.cuestionario-dos .contenido .resultado .panel-mensaje .puntaje{
  height: 125px;
  width: 125px;
  border-radius: 50px;
  background: var(--color-allivia);
  color: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: center;
}



.cuestionario-dos .contenido .resultado .panel-mensaje .numero{
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 31px;
  text-align: center;
}

.cuestionario-dos .contenido .resultado .panel-mensaje .texto{
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 13px;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 20px;
}

.cuestionario-dos .contenido .boton-siguiente{
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.cuestionario-dos .contenido .boton-siguiente ion-button{
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px; 
  width: 100%; 
}

