.mispacientes .titulo{
  text-transform: uppercase;
  color: var(--color-allivia);
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
}

.lista-mispacientes .item-paciente{    
  padding:3% 0;
}

.lista-mispacientes ion-item::part(native){ 
  --padding-start:0px;
  --padding-left: 0px;
  --padding-right:0px;
  --inner-padding-end: 0px;
}

.lista-mispacientes .item-paciente ion-img{    
  width: 40px;
}

.lista-mispacientes .paciente{    
  padding-left:5%;
  display: flex;
  flex-direction: row;
  font-weight: 500;
  font-size: .9em;
}

.lista-mispacientes .nombre-completo{
  text-transform: capitalize;
}

.lista-mispacientes .parentesco{
  color: var(--color-allivia-rosa);
}



.mi-paciente-compartido{
  display: flex;
  flex-direction: column;
  font-weight: 500;
  font-size: 14px;  
}

.mi-paciente-compartido .solicitud{
  padding-left: 10px;
  text-transform: uppercase;
  color: var(--color-allivia);
}

.ion-modal-aceptar-paciente{
  --width: 90%;
  --height: 320px;
  --border-radius: 8px;
}

.ion-modal-aceptar-paciente .contenido{
  padding-left: 30px;
  padding-right: 30px;
}

.ion-modal-aceptar-paciente .contenido .card-paciente{
  display: flex;
  border: solid 2px;
  border-color: var(--color-allivia);
  margin-bottom: 5px;
  flex-direction: column;
}

.ion-modal-aceptar-paciente .contenido .card-paciente .avatar{  
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.ion-modal-aceptar-paciente .contenido .card-paciente .avatar .izq{  
  width: 90%;
  display: flex;
  justify-content: center;
}

.ion-modal-aceptar-paciente .contenido .card-paciente .avatar .der{  
  width: 10%;
  text-align: right;
}

.ion-modal-aceptar-paciente .contenido .card-paciente .avatar .der ion-checkbox{
  --border-radius: 12px !important;
  --border-color: var(--color-allivia-rosa);
  --background-checked: var(--color-allivia-rosa);
  --border-color-checked: var(--color-allivia-rosa);
  width: 25px;
  height: 25px;
}

.ion-modal-aceptar-paciente .contenido .card-paciente ion-avatar{  
  width: 40px;
  height: 40px;
  transform: translate(40%);
}

.ion-modal-aceptar-paciente .contenido .card-paciente .paciente{
  display: flex;
  flex-direction: row;
  /* align-content: center; */
  justify-content: center;
}

.ion-modal-aceptar-paciente .contenido .card-paciente .paciente .paciente-datos .nombre-completo{
  text-align: right;
}

.ion-modal-aceptar-paciente .contenido .card-paciente .paciente .paciente-datos .edad{
  text-align: right;  
}
.ion-modal-aceptar-paciente .contenido .card-paciente .paciente .paciente-datos{
  width: 100%;
}

.ion-modal-aceptar-paciente .contenido .card-paciente .paciente .parentesco{
  width: 30%;
}

.ion-modal-aceptar-paciente .contenido .card-paciente .paciente .parentesco ion-label{
  color: var(--color-allivia-rosa);
}
  /*Pacientes compartidos*/



.titulocompartidos{
  font-size:1.5em;
  color: var(--color-allivia);
  font-weight: bold;
  font-variant: small-caps;
  text-align: center;
}


#pacientes_compartidos{
  width:100%;
  padding:3% 0;
}
#f_compartidos{
  background:#EDECEC;
  padding:1%;
  margin-bottom: 2%;
}

.solicitud{
  --color:var(--color-allivia-rosa);
  font-weight: bold;
  padding: 0 0 5% 0;
}

#compartir_paciente{
  width:100%;
  padding:30% 0;
}
#compartir_paciente h6{
  font-size:.9em;
  text-align:center;

}
#btn_compartir{
  margin:5% 0;

}