.video-respiracion{
  position: relative;
  width: 100%;
  height: 100%;
}

.video-padding{
  --padding-top: -38px;
}

.respira-header{
  --background: var(--color-allivia);
  color: #FFFFFF;
}

.respira-header ion-back-button{
  --color: #FFFFFF;
}

.respira-header ion-title{
  color: #FFFFFF;
}