.ion-modal-diagnosticos{
  --width: 90%;
  --height: 170px;
  --border-radius: 8px;
}
.ion-input:first-letter{
  text-transform: capitalize;
}
.ion-modal-diagnosticos ion-content{
  --padding-start: 10px;
  --padding-end: 10px;
  --padding-top: 10px;
}

.ion-modal-diagnosticos ion-label{
  font-size: 11px;
  line-height: 13px;
}

.panel-cuidador-form ion-accordion{
  background: rgba(222, 222, 222, 0.5);
}

.panel-cuidador-form ion-accordion ion-item{
  --background: var(--color-allivia);
  --color: white;
  font-weight: bold;
  font-size: 16px;
}

.panel-cuidador-form ion-accordion .link-curp{
  color: var(--color-allivia-rosa);
  font-weight: 900;
  font-size: 9px;
  text-transform: none;
}

.panel-cuidador-form ion-accordion ion-icon{
  color: white;
}

.panel-cuidador-form ion-accordion .panel-diagnosticos .panel-acciones{  
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content:center;
}
.panel-cuidador-form ion-accordion .panel-diagnosticos .panel-acciones ion-button::part(native){      
  padding: 0px;
}

.panel-cuidador-form ion-accordion .panel-diagnosticos .panel-add-diag{
  text-align: right;
}

.panel-cuidador-form ion-accordion .panel-diagnosticos .panel-add-diag ion-button::part(native){      
  padding: 5px;
}

.panel-cuidador-form ion-accordion .panel-benef .panel-acciones{  
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content:center;
}

.panel-cuidador-form ion-accordion .panel-benef .panel-add-benef{
  text-align: right;
}

.panel-cuidador-form ion-accordion .panel-benef .panel-acciones ion-button::part(native){      
  padding: 0px;
}

.panel-cuidador-form ion-accordion .upload-panel{
  border-radius: 10px;
  width: 100%;
  min-height: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #E8E8E8;
  margin-top: 20px;
  margin-bottom: 10px;
  color: #747474;
  padding: 10px;
  text-align: center;
}

.panel-cuidador-form ion-accordion .upload-panel .upload-panel-buttons{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.panel-cuidador-form ion-accordion .ds-titulo{
  color: var(--color-allivia);
  font-size: 12px;
  font-weight: bold;
}

.panel-cuidador-form ion-accordion .ds-aviso{
  font-size: 12px;
}

.panel-cuidador .avatar{ 
  text-align: center;
  margin: 0 auto;
  margin-bottom: 10px;
}
.btn-avatar{  
  transform: translate(45px,-38px); /* Equivalente a translateX(10px) */
  --padding-start: 5px;
  --padding-end: 5px;
  --background: var(--color-allivia-gris);
}

.panel-cuidador .nombreCompleto{
  text-transform: capitalize;
}


.panel-cuidador_perfil {
  font-size: .8em;
  color:#330000;
}

#avatar_perfil{
  width:100%;
}
.avatar{
 margin:0 auto;
}
/*títulos css figma*/

.titulos_interiores{
  font-size:.9em;
  text-align: center;
}

.input-wrapper{
  font-size:1em;
}
.camera{
  background: #c4c4c4;
}
.nombreCompleto{
  text-align: center;
  background: #fff!important;
  color:#000!important;
}
.grupo_datos{
  padding:3% 0;
  background:#fff!important;
  border:none!important;

}
.item button{
  border-width:0 0 0 0!important;
}
.title_datos{
    font-size:.9em !important;
}
body{
  font-size:.9em;
}

.panel-cuidador-datos ion-label{
  font-size: .8rem;
}

.upload-preview{  
  padding: 10px;
}

.upload-preview img{
  border-radius: 10px;
  max-height: 100px;
}

.saveFabButton{
  --background: #FF6B00;
  --box-shadow: 0px 0px 0px 7px #ff6a0031;
}

.saveFabButton ion-img{
  width: 35px;
  height: 35px;
}

.ion-modal-alergia{
  --width: 90%;
  --height: 170px;
  --border-radius: 8px;
}

.ion-modal-alergia ion-content{
  --padding-start: 10px;
  --padding-end: 10px;
  --padding-top: 10px;
}

.ion-modal-alergia ion-label{
  font-size: 11px;
  line-height: 13px;
}

.recordar-guardar{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ion-modal-beneficiarios{
  --width: 90%;
  --height: 220px;
  --border-radius: 8px;
}

.ion-modal-beneficiarios ion-content{
  --padding-start: 10px;
  --padding-end: 10px;
  --padding-top: 10px;
}

.ion-modal-beneficiarios ion-label{
  font-size: 11px;
  line-height: 13px;
}

.hide-benef ion-input{
  background: rgba(196, 196, 196, 0.3);
  opacity: 1;
}

.ion-modal-cambios-singuardar{
  --width: 90%;
  --height: 170px;
  --border-radius: 8px;
}

.ion-modal-cambios-singuardar ion-content{
  --padding-start: 10px;
  --padding-end: 10px;
  --padding-top: 10px;
}

.ion-modal-cambios-singuardar ion-label{
  font-size: 11px;
  line-height: 13px;
}

.allivia-input-disabled input {
  opacity: 1 !important;
}