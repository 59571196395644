:root {
  --aqua-main: #65c8d0;
  --pink-second: #f078c0;
  --pink-bueno: #f078c0;
  --textos: #565656;
  --blanco: #fff;
  --textos-desactivos: #aeaeae;
  --inputs: #e8e8e8;
  --bot-n-desactivados: #dedede;
  --bot-n-desactivado: #dedede;
  --color-tablas: #333232;
  --pink-agua: rgba(240, 120, 192, 0.1);
  --rosa-opaco: rgba(240, 120, 192, 0.1);
  --fondo-tablas-aqua: rgba(101, 200, 208, 0.4);
  --fondo-tablas-pink: rgba(240, 120, 192, 0.4);
  --aqua-flechas-signos-vitales: rgba(101, 200, 208, 0.7);
  --caregiver: #1a607c;
  --fondo-textos: #f4f4f4;
  --fondo-filas-pink: rgba(240, 120, 192, 0.15);
  --text-secondary:#565656;
  --fondo-mem-gray:#DEDEDE;
  --naranja-terc:#FF6B00;
  --red-emergencia:#FF0000;
}
.homepage{
  --background:  #F078C026;
}
.home-title{
  text-align: center;
}
.homepage-avatar{
  width:29px;
  height:31px!important;
}

.homepage-iniciales{  
  border-radius: 15px;
  background: var(--color-allivia-rosa);
  width: 30px;
  height:30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  text-align: center;
}
.title_header {
  font-size: 1.1em;
  text-align: center;
}
.homepage ion-fab-button {
  --box-shadow: 0px 0px 0px 7px #fdebf6;

}
#avatar span{
  width: 43px!important;
  height: 43px;
}


/*.homepage span{
  text-transform: capitalize;
}
*/
/*panel cuidador*/

.panel-cuidador{
  padding-top: 20px; 
  text-align: center;
  background: white;
  padding:1% 6%;
}

.panel-pacientes{
  background: white;
  width: 100%;
  position: relative;
  bottom: 0; 
}

.panel-pacientes .cuido-a{
  color: var(--color-allivia-rosa);
  padding: 0px;  
  text-transform: none;
  font-size: 12px;
}
ion-input {
  --text-transform:capitalize;
}
.panel-pacientes ion-img{
 width: 140px;
 padding-left: 61px;
}

.panel-pacientes ion-button{
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  color: var(--color-allivia-rosa);
  text-align: left;
}

.panel-pacientes img{
  width: 72px;
}

.panel-pacientes ion-col{
  text-align: center;
  text-transform: capitalize;
  line-height: 11px;
  font-size: 10px;
}

.panel-pacientes ion-grid img{
  width: 63px;
  
}

.panel-pacientes ion-grid ion-avatar{
  width: 100%;
  padding: 0px;
  height: 63px;
} 

.panel-pacientes ion-router-link{
  text-decoration: none;
  color: #000000;
}

.bienvenida {
  font-size: 1.1em;
  font-weight: bold;
  align-items: center;
  text-align: center;
  color: #000;
  height: 5px;
}
.deseas_r{
  font-size: .95em;
  text-align: center;
  color: #000;

}
.deseas_r span{
    text-transform: none !important;
}

.panel-pacientes ion-button{
  height: 120px;
  width: 100px;
  --padding-start: none;
  --padding-end: none;  
}

.panel-pacientes ion-button div{
  display: flex ;
  flex-direction: column;
  align-items: center !important;
  justify-content: center;
}
.panel-pacientes ion-button ion-img{  
  width: 72px;
  margin: 0px;
}

.panel-pacientes ion-button span{
  padding-top: 5px;
  font-size: .8em;
  color:var(--textos);
}

.panel-pacientes button{
  background: none;
  margin: 20px;
  width: 80px;
  height: 74px;
}

.panel-pacientes button p{
  margin: 0px;   
  font-weight: 500;
  font-size: 10px;
  /* Textos */
  color: #565656;
}

.cuido-a{
  /*usuar cuando es un componente de ionic*/
  /*--background:  #F078C026;*/
  /*background:  #F078C026;*/
  width: 100%;
  position: relative;
  bottom: 0; 
}

#txt_cuido{
  padding:0 6.4%;
  font-size:.95em;
}

.cuido-a ion-list-header{
  background: var(--color-allivia-rosa);
}

.cuido-a ion-list-header ion-label{
  color: #FFFFFF;
  font-weight: bold;
  font-size: 1em;
  line-height: 18px;
  margin-top: 0px;
}

.cuido-a ion-button{
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  color: var(--color-allivia-rosa);
  text-align: left;
}

.cuido-a .premium{
  background: #3F3F3F;
  padding: 10px;
  margin: 5px 30px 35px 30px;
}

.cuido-a .premium .header{  
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    /* or 107% */  
    /* Aqua main */  
    color: #65C8D0;  
}

.cuido-a .premium .body{  
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  color: #FFFFFF; 
}


.cuido-a img{
  width: 72px;
}


.cuido-a .mensaje{
  /* Títulos */
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  color: #000000;
}

.cuido-a .panel-mensaje{  
  padding: 5px;
  margin: 5px 30px 5px 30px;
}

.cuido-a ion-col{
  text-align: center;
  text-transform: capitalize;
  line-height: 11px;
  font-size: 10px;
}

.cuido-a ion-grid img{
  width: 63px;
  
}
.cuido-a ion-grid ion-avatar{
  width: 100%;
  padding: 0px;
  height: 63px;
} 

.cuido-a ion-router-link{
  text-decoration: none;
  color: #000000;
}
#beneficios{
  font-size:1em;
  text-align: left!important;
  font-variant: normal!important;
}
#txt_premium{
  width:100%;
}

#txt_premium p{
  font-size:1.3em;
  text-align: justify;
  padding-left:12%;
  line-height: normal;
  text-transform: lowercase;
}
.capital{
  text-transform: capitalize;
}
/*rediseño home menú*/

.opciones_menu{
  width:100%;
}
.avatarm{
  width:60px;
}
.wecalendar{
  width:30%;
}
:host{
  border:none!important;
}
.name{
  font-size:.8em;
  color:var(--textos);
}
#freemium{
  width:100%;
}
#calendario{
  float:left;
}
.calendario{
  color:#fff;
}
/*menu inferior verde*/
.menuinferior{
  border-top: none !important;
}
/*avatar*/

.sc-ion-buttons-md-s ion-but{
  height: 66px!important;
}

#pacientes_compartidos{
  width:100%;
}
#pacientes_compartidos h6{
  margin:0 6%;
  font-weight: bold;
  font-variant: small-caps;
  font-size:.9em;
}
.ion-modal-cuida-de-ti{
  --width: 70%;
  --height: 180px;
  --border-radius: 8px;
}

.ion-modal-cuida-de-ti ion-content{
  --padding-start: 5px;
  --padding-end: 5px;
  --padding-top: 5px;
}

.ion-modal-cuida-de-ti .header{
  color: var(--color-allivia-rosa);
  text-align: right;
  padding: 0px;
}

.ion-modal-cuida-de-ti .header ion-button{
 width: 50px;
 height: 30px;
}



.ion-modal-cuida-de-ti .header ion-button::part(native){
  padding: 0px;
}

.ion-modal-cuida-de-ti .contenido{
  font-size: 14px;
  padding: 0px;
}

.ion-modal-cuida-de-ti .contenido ion-button::part(native){
  padding: 0px;
 }

.ion-modal-cuida-de-ti .contenido ion-button .cuida-de-ti-imagen{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ion-modal-cuida-de-ti .contenido ion-button{
  height: 80px;
  width:90%;
  margin: 0 5%;
}

.ion-modal-cuida-de-ti .contenido ion-button ion-img{
  width: 60px
}

.ion-modal-cuida-de-ti .contenido ion-button ion-label{
  font-style: normal;
  font-weight: 500;
  font-size: 9px;
  line-height: 10px;
  color: #565656;
}


.ion-modal-eliminar-paciente{
  --width: 90%;
  --height: 160px;
  --border-radius: 8px;
}

.ion-modal-eliminar-paciente ion-content{
  --padding-start: 20px;
  --padding-end: 20px;
  --padding-top: 40px;
}

.ion-modal-eliminar-paciente .header{
  color: var(--color-allivia-rosa);
  text-align: right;
  padding: 0px;
}

.ion-modal-eliminar-paciente .header ion-button::part(native){
  padding: 0px;
}

.ion-modal-eliminar-paciente .contenido{
  font-size: 14px;
  padding: 0px;
}

.ion-modal-eliminar-paciente .contenido .nombre{
  font-weight: bold;
}

.ion-modal-eliminar-paciente .contenido ion-button::part(native){
  padding: 0px;
}

.ion-modal-eliminar-paciente ion-grid{
  padding-left: 0px;
  padding-right: 0px;
}

.ion-modal-eliminar-paciente ion-row{
  padding-left: 0px;
  padding-right: 0px;
}

.ion-modal-eliminar-paciente ion-col{
  padding-left: 0px;
  padding-right: 0px;
}


.ion-modal-eliminar-paciente-compartido{
  --width: 90%;
  --height: 160px;
  --border-radius: 8px;
}

.ion-modal-eliminar-paciente-compartido ion-content{
  --padding-start: 20px;
  --padding-end: 20px;
  --padding-top: 40px;
}

.ion-modal-eliminar-paciente-compartido .header{
  color: var(--color-allivia-rosa);
  text-align: right;
  padding: 0px;
}

.ion-modal-eliminar-paciente-compartido .header ion-button::part(native){
  padding: 0px;
}

.ion-modal-eliminar-paciente-compartido .contenido{
  font-size: 14px;
  padding: 0px;
}

.ion-modal-eliminar-paciente-compartido .contenido .nombre{
  font-weight: bold;
}

.ion-modal-eliminar-paciente-compartido .contenido ion-button::part(native){
  padding: 0px;
}

.ion-modal-eliminar-paciente-compartido ion-grid{
  padding-left: 0px;
  padding-right: 0px;
}

.ion-modal-eliminar-paciente-compartido ion-row{
  padding-left: 0px;
  padding-right: 0px;
}

.ion-modal-eliminar-paciente-compartido ion-col{
  padding-left: 0px;
  padding-right: 0px;
}

.capitalizar{
  text-transform: capitalize;

}
#textos_allivia{
  width:80%;
  margin:0 auto;
}
#textos_allivia p{
  font-size:.9em;
  text-align: justify;
}


/*IOS*/

.ios #txt_cuido{
  font-size: .6em;
}
