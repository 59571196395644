.ion-modal-buscar{
  --width: 90%;
  --height: 90%;
  --border-radius: 8px;
}

.buscar-padding{
  --padding-start: var(--ion-padding, 10px);
  --padding-end: var(--ion-padding, 10px);
  --padding-top: var(--ion-padding, 10px);
  padding-right: var(--ion-padding, 10px);
  padding-top: var(--ion-padding, 10px);
  padding-bottom: var(--ion-padding, 10px);
}