.medicamento ion-img{
  width: 60px;
  margin-left: 50px;
  margin-inline: 20px;
}


.medicamento ion-item{
  margin-top: 20px;
}

.medicamento ion-list-header{ 
  background: rgba(240, 120, 192, 0.3);
}

.medicamento ion-list-header ion-img{
  width: 35px; 
}

.medicamento .header-nombre{
  font-size: 16px;
  line-height: 18px;
  color: #565656;
  text-transform: capitalize;
}

.medicamento .header-parentesco{
  font-size: 16px;
  line-height: 18px;
  color: #565656;
  text-transform: capitalize;
}


.medicamento .medicamento-panel{   
   padding: 30px;
   width: 100%;
}

.medicamento .medicamento-panel .panel-diagnostico{
  font-size: 12px;
  background: rgba(101, 200, 208, 0.15);
  margin-top: 30px;
}

.medicamento .medicamento-panel .mensaje-sin-registros{
  font-size: 12px;
  margin-top: 30px;
}

.medicamento .medicamento-panel .panel-diagnostico ion-label{
  font-weight: bold;
}

.medicamento .medicamento-panel .panel-diagnostico ion-img{
  width: 20px;

}

.medicamento .medicamento-panel .panel-diagnostico ion-col{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.medicamento .medicamento-panel .upload-panel{
  border-radius: 10px;
  width: 100%;
  min-height: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #E8E8E8;
  margin-top: 20px;
  margin-bottom: 10px;
  color: #747474;
}

.medicamento .medicamento-titulo{
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: #565656;
  
}

.medicamento .medicamento-titulo .pastilla-medicamento{
  width: 50px;
}

.medicamento .medicamento-panel .pastilla-medicamento{
  text-align: center;
  position: relative;
  text-align: center;
  margin-left: 20px;
  margin-right: 20px;
}

.medicamento .medicamento-panel .pastilla-medicamento ion-img{
  width: 100%;
  margin: 0px;
}

.medicamento .medicamento-panel .pastilla-medicamento span{
  display: block;
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -20%);
  font-size: 11px;
  white-space: nowrap;
  width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
}





.medicamento .medicamento-datos{
  margin-top: 40px;

}

.medicamento .medicamento-datos table{
  width: 100%; 
  text-align: center; 
}

.medicamento .medicamento-datos thead{
  background: rgba(240, 120, 192, 0.4);
  font-weight: 500;
  font-size: .7em;
  line-height: 14px;
}

.medicamento .medicamento-datos tbody{
  font-size: .8em;
  line-height: 14px;
}

.medicamento .medicamento-datos table td {
  border-left: 1px solid black; 
  border-right: 1px solid black; 
}



.medicamento .medicamento-datos table tr td:first-child {
  border-left: 0;
}

.medicamento .medicamento-datos table tr td:last-child {
  border-right: 0;
}

.medicamento .medicamento-datos tr:nth-child(even){
  background: rgba(240, 120, 192, 0.15);;
}

.medicamento .medicamento-datos .panel-acciones{  
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content:center;
  border-left: 0;
}
.medicamento .medicamento-datos .panel-acciones ion-button::part(native){      
  padding: 0px;
}

.ion-modal-medicamento{
  --width: 90%;
  --height: 580px;
  --border-radius: 8px;
}

.ion-modal-medicamento ion-content{
  --padding-start: 20px;
  --padding-end: 20px;
  --padding-top: 20px;
}

.ion-modal-medicamento .header{
  color: var(--color-allivia-rosa);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}

.ion-modal-medicamento .contenido{
  font-size: 14px;
}

.ion-modal-medicamento .contenido .indicaciones{
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  color: var(--color-allivia);
}

.ion-modal-medicamento .contenido ion-col{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ion-modal-medicamento .contenido ion-radio{
  color: black;
}

.ion-modal-medicamento .contenido ion-img{
  width: 30px;
  padding:0 5px;
}

.ion-modal-medicamento .contenido ion-item ion-input{
  width: 40%;
}

.ion-modal-medicamento .header ion-img{
  width: 46px;
}

.ion-modal-medicamento .header ion-segment{
  font-size: 14px;
}

.ion-modal-medicamento .contenido ion-select{
  width: 100%;
}

.ion-modal-medicamento .panel-documentos .upload-panel{
  border-radius: 10px;
  width: 100%;
  min-height: 80px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: #E8E8E8;
  margin-top: 20px;
  margin-bottom: 10px;
}

.titulos_medicina th{
  padding:1.1%;
}
.listas_datos {
  --padding-start: 5px;

}
/*IOS*/
.ios .datos_px {
  padding-top: 4%;
}

.ios #title_parentesco {
  margin-top: -9px;
}

.ios .header-nombre {
  font-size: .65em;
  color: #565656;
  font-weight: lighter;
}

.ios .header-parentesco {
  font-size: .65em;
  font-weight: lighter;
  --padding-top: -3px;
}

.ios .info_cabecera {
  display: inline-block;
  width: 100%;
  padding: 1% 0 0 1%;

}


/*Estilos para IOS*/


.ios .listas_datos {
  --padding-start: 5px;

}